import { React, useEffect, useState } from "react";
import { useQuery } from "../../hooks"
import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin,DatePicker,InputNumber,Switch ,Table} from 'antd'
import {
  EditOutlined,
  PlusOutlined,
  UserOutlined ,
  CloseCircleOutlined,
  DeleteOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { red } from "@ant-design/colors";
import { useHistory } from "react-router-dom";
import moment from "moment";
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';
import { auth,firestore } from "../../services/firebase";
import { getDoc ,setDoc, doc, serverTimestamp,updateDoc,query,collection,onSnapshot,deleteDoc } from "firebase/firestore";
import DefaultLayout from "../../components/layouts/DefaultLayout";
import { initializeApp,deleteApp} from "firebase/app";
import { getAuth,createUserWithEmailAndPassword,signOut } from 'firebase/auth';
import { DropdownOverlayButton } from "../../components";

const {
  REACT_APP_FB_API_KEY: apiKey,
  REACT_APP_FB_AUTH_DOMAIN: authDomain,
  REACT_APP_FB_DB_URL: databaseURL,
  REACT_APP_FB_PROJ_ID: projectId,
  REACT_APP_FB_STORAGE: storageBucket,
  REACT_APP_FB_SENDER_ID: messagingSenderId,
  REACT_APP_FB_APP_ID: appId,
} = process.env;

const config = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
};

const CompanyForm = () => {
  const renderSimple = (prop) => prop || <i>--</i>;

  const q = useQuery()
  const idUsuario = q.get("id")?.replace(' ','')
  const editing = !!idUsuario
  const history = useHistory()
  const [saveLoading, setSaveLoading] = useState(false)
  const [form] = Form.useForm()
  const {Option} = Select
  const currentUser = auth.currentUser.uid;
  const [model, setModel] = useState({})
  const [paquete, setPaquete] = useState({})
  const [paquetes, setPaquetes] = useState([])
  const [pagos, setPagos] = useState("")
  const [modelLoading, setModelLoading] = useState(true)
  const breadcrumb=[
    { name: "Usuarios", to: "/admin/usuarios", icon: <UserOutlined /> },
    { name: editing?"Editar":"Nuevo", to: editing ? `/admin/usuarios/editar?id=${idUsuario}` : "/admin/usuarios/nueva", icon: editing?<EditOutlined />:<PlusOutlined /> }
  ];
  const editingColumns = [
    {
      title: "Nombre",
      width: 150,
      dataIndex: "nombre",
      key: "nombre",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Precio",
      width: 150,
      dataIndex: "precio",
      key: "precio",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Años",
      width: 150,
      dataIndex: "years",
      key: "years",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Cobertura",
      width: 150,
      dataIndex: "cobertura",
      key: "cobertura",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "center",
      width: 100,
      render: (text, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => modalDelete(row),
              styleProps: {
                color: red[4],
              },
            },
          ]}
        />
      ),
    },
  ];
  const columns = [
    {
      title: "Nombre",
      width: 150,
      dataIndex: "nombre",
      key: "nombre",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Precio",
      width: 150,
      dataIndex: "precio",
      key: "precio",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Años",
      width: 150,
      dataIndex: "years",
      key: "years",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Cobertura",
      width: 150,
      dataIndex: "cobertura",
      key: "cobertura",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "center",
      width: 100,
      render: (text, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Asignar Paquete",
              icon: <LockOutlined />,
              onClick: () => {
                setPaquete(row);
                messageSuccess();
              }
            },

            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => modalDelete(row),
              styleProps: {
                color: red[4],
              },
            },
          ]}
        />
      ),
    },
  ];
  const modalDelete = (v) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar el paquete "${v.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        try {
          delete model["paquete"];
          setPaquete({});
        } catch (error) {
          console.log(error);
        }
      },
    });
  };
  useEffect(() => {
    const q = query(collection(firestore, "paquetes"));
    onSnapshot(q, (querySnapshot) => {
      const paquetes2 = [];
      querySnapshot.forEach((doc) => {
          paquetes2.push(doc.data());
      });
      const sortedObjs = paquetes2.sort((a, b) => a.nombre.localeCompare(b.nombre));

      console.log(sortedObjs);
      setPaquetes(sortedObjs);

    });
}, []);



useEffect(() => {
  if(!editing)  
  setModelLoading(false);
  if(editing && modelLoading){
    console.log(idUsuario);
    const ref= doc(firestore,"users",idUsuario)
      getDoc(ref).then(docSnap=>{
      if (docSnap.exists()) {
        const data=docSnap.data();
        setModel(data);

        if (modelLoading && model) {
          try{
            setPagos(model.numeroPagos);
          }catch(error){console.log(error)}
          try{
            model.primerpagoFecha=moment(model.primerpagoFecha.toDate());
          }
          catch(error){
            console.log("error-catch-model",error);
          }
          try{
            model.segundopagoFecha=moment(model.segundopagoFecha.toDate());
          }
          catch(error){
            console.log("error-catch-model",error);
          }
          try{
            model.tercerpagoFecha=moment(model.tercerpagoFecha.toDate());
          }
          catch(error){
            console.log("error-catch-model",error);
          }
          try{
            model.fechaTerminacion=moment(model.fechaTerminacion.toDate());
          }
          catch(error){
            console.log("error-catch-model",error);
          }
          form.setFieldsValue(model);
          setModelLoading(false);
          if(model?.paquete){
            console.log(model.paquete);
            setPaquete(model.paquete)
          }
        }
      } else {
        console.log("No such document!");
      }
    })
  } 
}, [model, form, editing, modelLoading, idUsuario]); 

const messageSuccess = () => {
  let secondsToGo = 2;
  const modal = Modal.success({
    title: "Datos actualizados correctamente!",
    cancelButtonProps: { style: { display: 'none' } },
    okButtonProps: { style: { display: 'none' } }
  });
  const timer = setInterval(() => {secondsToGo -= 1}, 1000);
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
  }, secondsToGo * 1000);
}

const onSubmit = async () => {
  try{
    try{
      await form.validateFields()
    }catch{
      return
    } 
    let data = form.getFieldsValue(true);
    if(paquete){

      data.paquete=paquete;
      data.paquete.fecha=serverTimestamp();
    }
        if(data?.primerpagoFecha){
          try{
            let d1=data?.primerpagoFecha?._d;
            delete data["primerpagoFecha"];
            data.primerpagoFecha=d1;
            }
            catch(error){console.log(error);}
        }
        if(data?.segundopagoFecha){
          try{
            let d2=data?.segundopagoFecha?._d;

            delete data["segundopagoFecha"];

            data.segundopagoFecha=d2;
            }
            catch(error){console.log(error);}
        }
        if(data?.tercerpagoFecha){
          try{
            let d3=data?.tercerpagoFecha?._d;

            delete data["tercerpagoFecha"];

            data.tercerpagoFecha=d3;
            }
            catch(error){console.log(error);}
        }

        if(data?.fechaTerminacion){
          try{
            let d4=data?.fechaTerminacion?._d;

            delete data["fechaTerminacion"];

            data.fechaTerminacion =d4;
            }
            catch(error){console.log(error);}
        }
    data.user=currentUser;
    if (editing){

        setModelLoading(false);
        data.id=idUsuario;
        updateDoc(doc(firestore,"users",idUsuario),data).then(()=>{
        history.push("/admin/usuarios")
        messageSuccess();
        });
    }else{
        setModelLoading(false);
        data.fecha = serverTimestamp();
        const secondaryApp = initializeApp(config,"secondary");
        const secondaryAuth = getAuth(secondaryApp);
        createUserWithEmailAndPassword(secondaryAuth,data.email,"promenalsonora2022").then(user=>{
        signOut(secondaryAuth);
        deleteApp(secondaryApp);
        data.id=user.user.uid;
        setDoc(doc(firestore,"users",user.user.uid),data).then(()=>{
        history.push("/admin/usuarios")
        messageSuccess();
        });
      }).catch(error=>{
        Modal.error({
          title: "Error",
          content: "Correo ya esta en existencia",
        });
      });
    }
  } catch(error){
    console.log("error-catch",error)
  } finally{
    setSaveLoading(false)
  }
};

  function onChange(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
  }
  function onOk(value) {
    console.log('onOk: ', value);
  }
  const TypographySuccess = () => {
    let secondsToGo = 2;
    const modal = Modal.success({
      title: editing ? "Datos actualizados correctamente." : "Datos guardados correctamente.",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { style: { display: 'none' } }
    });
    const timer = setInterval(() => {secondsToGo -= 1}, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }
if(modelLoading) return <Space size="middle"><Spin size="large"/></Space>
  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing ? `Editando Usuario: ${model.nombre}` : "Nuevo usuario"}
    >
      <Form
        component="div"
        form={form}
        layout="vertical"
      >
        <h1 style={{fontSize:30}}>Datos Fiscales</h1>
        <Row  gutter={[16, 0]}>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="nombre"
              label="Nombre"
              hasFeedback
              rules={[
                {
                  required:true,
                  message:"Esta campo es obligatorio."
                }
              ]}
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Nombre"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="apellidoPaterno"
              label="Apellido Paterno"
              hasFeedback
              rules={[
                {
                  required:true,
                  message:"Esta campo es obligatorio."
                }
              ]}
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Apellido Paterno"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="apellidoMaterno"
              label="Apellido Materno"
              hasFeedback
              rules={[
                {
                  required:true,
                  message:"Esta campo es obligatorio."
                }
              ]}
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Apellido Materno"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="rfc"
              label="R.F.C."
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el R.F.C."
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="curp"
              label="CURP"
              hasFeedback
     
            >
              <Input 
                maxLength={14}
                placeholder="Escribe el CURP"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="cedulaProfessional"
              label="Cédula Profesional"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Cédula Professional"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="especialidad"
              label="Especialidad"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Especialidad"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="cedulaEspecialidad"
              label="Cédula de Especialidad"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Cédula de Especialidad"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="calle"
              label="Calle"
              hasFeedback
     

            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Calle"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroExterior"
              label="Numero Exterior"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero exterior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroInterior"
              label="Numero Interior"
              hasFeedback
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero interior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="colonia"
              label="Colonia"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la colonia"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="municipio"
              label="Delegación o municipio"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el municipio"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="estado"
              label="Estado"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el estado"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="codigoPostal"
              label="Código Postal"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Código Postal"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="telefono"
              label="Teléfono "
              required
              hasFeedback
     
            >
              <Input 
                maxLength={14}
                placeholder="Escribe el teléfono"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="email"
              label="Correo electrónico"
              hasFeedback
              rules={[{ type: 'email' }]}
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el correo electrónico"
              />
            </Form.Item>
          </Col>
        </Row>
        <h1 style={{fontSize:30, paddingTop:10}}>Consultorio</h1>
        <Row gutter={[16, 0]}>
        <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="calleConsultorio"
              label="Calle"
              hasFeedback
     

            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Calle"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroExteriorConsultorio"
              label="Numero Exterior"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero exterior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroInteriorConsultorio"
              label="Numero Interior"
              hasFeedback
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero interior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="coloniaConsultorio"
              label="Colonia"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la colonia"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="municipioConsultorio"
              label="Delegación o municipio"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el municipio"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="estadoConsultorio"
              label="Estado"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el estado"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="codigoPostalConsultorio"
              label="Código Postal"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Código Postal"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="telefonoConsultorio"
              label="Teléfono "
              hasFeedback
     
            >
              <Input 
                maxLength={14}
                placeholder="Escribe el teléfono"
              />
            </Form.Item>
          </Col>
          {!editing && (
              <Col sm={12} md={8}>
                <Form.Item
                  name="password"
                  label="Contraseña"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, escriba la contraseña!',
                    },
                    {
                      min:8,
                      message: 'Contraseña debe tener minimo 8 caracteres',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            )        
          }
        </Row>
        <h1 style={{fontSize:30, paddingTop:10}}>Hospital</h1>
        <Row gutter={[16, 0]}>
        <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="nombreHospital"
              label="Nombre"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Nombre"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="servicio"
              label="Servicio"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el servicio"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="calleHospital"
              label="Calle"
              hasFeedback
     

            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Calle"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroExteriorHospital"
              label="Numero Exterior"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero exterior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroInteriorHospital"
              label="Numero Interior"
              hasFeedback
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero interior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="coloniaHospital"
              label="Colonia"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la colonia"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="municipioHospital"
              label="Delegación o municipio"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el municipio"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="estadoHospital"
              label="Estado"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el estado"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="codigoPostalHospital"
              label="Código Postal"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Código Postal"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="telefonoHospital"
              label="Teléfono "
              hasFeedback
     
            >
              <Input 
                maxLength={14}
                placeholder="Escribe el teléfono"
              />
            </Form.Item>
          </Col>
        </Row>
        <h1 style={{fontSize:30, paddingTop:10}}>Datos de Pago</h1>
        <Row gutter={[16, 0]}>
  
        <Col sm={24} md={4}>
          <Form.Item 
            style={{ marginBottom: '0px' }}
            required
            hasFeedback
            name="fechaTerminacion"
            label= "Terminacion"

          >
            <DatePicker  
          locale={locale}
            style={{ width:"100%" }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                
                    onChange={onChange}
                    onOk={onOk}
                    placeholder="Fecha"
            />
          </Form.Item>
          
        </Col>
         <Col sm={12} md={4}>
          <Form.Item 
              style={{ marginBottom: '0px' ,marginRight: '20px'}}
              required
              name="metodoPago"
              label="Metodo de Pago"
              hasFeedback

            >
              <Select
                defaultValue={model.metodoPago}
                showSearch  
                style={{ width: "100%" }}
                listItemHeight={10} 
                listHeight={250}
                placeholder="Seleccione el metodo de pago"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={"CHEQUE"} value={"CHEQUE"}>{"CHEQUE"}</Option>
                <Option key={"EFECTIVO"} value={"EFECTIVO"}>{"EFECTIVO"}</Option>
                <Option key={"CREDITO"} value={"CREDITO"}>{"CREDITO"}</Option>
                <Option key={"TRANSFERENCIA"} value={"TRANSFERENCIA"}>{"TRANSFERENCIA"}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={12} md={3}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="deposito"
              label="Deposito"
              hasFeedback

            >
              <InputNumber
              />
            </Form.Item>
          </Col> 

          <Col sm={12} md={2}>
          <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="numeroPagos"
              label="Pagos"
              hasFeedback

            >
              <Select
                onChange={(pago)=>{setPagos(pago);}}
                defaultValue={model.numeroPagos}
                showSearch  
                listItemHeight={10} 
                listHeight={250}
                placeholder="Seleccione el numero de pagos"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                       <Option key={"1"} value={1}>{"1"}</Option>
                       <Option key={"1"} value={1}>{"1"}</Option>
                       <Option key={"2"} value={2}>{"2"}</Option>
                       <Option key={"3"} value={3}>{"3"}</Option>

              </Select>
            </Form.Item>
          </Col>
        </Row>
<Row style={{paddingTop:20}} gutter={[16, 0]}>
{pagos>=1?(
          <Col sm={24} md={4}>
          <Form.Item 
            style={{ marginBottom: '0px' }}
            required
            hasFeedback
            name="primerpagoFecha"
            label= "Fecha Primer Pago"

          >
            <DatePicker  
          locale={locale}
            style={{ width:"100%" }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                
                    onChange={onChange}
                    onOk={onOk}
                    placeholder="Fecha"
            />
          </Form.Item>
          
        </Col>
        ):null}
        {pagos>=1?(
                  <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="primerpago"
                    label="Primer Pago"
                    hasFeedback

                  >
                    <InputNumber
                    style={{width:"100%"}}
                      min={1}
                      initalValue={1}
                    />
                  </Form.Item>
                </Col>
      ):null}
              {pagos>=1?(
                  <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="primerPagado"
                    label="Pagado"
                    hasFeedback

                  >
                    <Switch
                    defaultChecked={model.primerPagado}
                    style={{width:"70%"}}
                    />
                  </Form.Item>
                </Col>
      ):null}
      </Row>
      <Row  gutter={[16,0]}>
{pagos>=2?(
          <Col sm={24} md={4}>
          <Form.Item 
            style={{ marginBottom: '0px' }}
            required
            hasFeedback
            name="segundopagoFecha"
            label= "Fecha Segundo Pago"

          >
            <DatePicker  
          locale={locale}
            style={{ width:"100%" }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                
                    onChange={onChange}
                    onOk={onOk}
                    placeholder="Fecha"
            />
          </Form.Item>
          
        </Col>
      ):null}
{pagos>=2?(
        <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="segundopago"
                    label="Segundo Pago"
                    hasFeedback
             
                  >
                    <InputNumber
                      style={{width:"100%"}}
                      min={1}
                      initalValue={1}
                    />
                  </Form.Item>
                </Col>
               
               ):null}
                             {pagos>=2?(
                  <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="segundoPagado"
                    label="Pagado"
                    hasFeedback

                  >
                    <Switch
                    defaultChecked={model.segundoPagado}
                    style={{width:"70%"}}
                    />
                  </Form.Item>
                </Col>
      ):null}
  </Row>
<Row gutter={[16,0]}>
{pagos>=3?(
        <Col sm={24} md={4}>
          <Form.Item 
            style={{ marginBottom: '0px' }}
            required
            hasFeedback
            name="tercerpagoFecha"
            label= "Fecha Tercer Pago"
  
          >
            <DatePicker  
          locale={locale}
            style={{ width:"100%" }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                
                    onChange={onChange}
                    onOk={onOk}
                    placeholder="Fecha"
            />
          </Form.Item>
          
        </Col>
):null}
{pagos>=3?(

                <Col sm={24} md={3}>
                <Form.Item 
                  style={{ marginBottom: '0px' }}
                  required
                  name="tercerpago"
                  label="Tercer Pago"
                  hasFeedback
        
                >
                  <InputNumber
                    style={{width:"100%"}}
                    min={1}
                    initalValue={1}
                  />
                </Form.Item>
              </Col>
):null}
              {pagos>=3?(
                  <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="tercerPagado"
                    label="Pagado"
                    hasFeedback

                  >
                    <Switch
                    defaultChecked={model.tercerPagado}
                    style={{width:"70%"}}
                    />
                  </Form.Item>
                </Col>
      ):null}
  </Row>

        <Row gutter={[16, 0]}  style={{ marginTop: '10px' }}>
          <Col sm={24}>
            <Form.Item>
              <Button 
                type="primary" 
                onClick={onSubmit}
                loading={saveLoading}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row> 
{paquete ? 
<>   <h1 style={{fontSize:30}}>Paquete Asignado</h1>

   <Table
     columns={editingColumns}
     dataSource={[paquete]}
     rowKey="id"
   />
   </>
 :null

}
     

        <h1 style={{fontSize:30}}>Paquetes</h1>

            <Table
          columns={columns}
          dataSource={paquetes}
          rowKey="id"
        />
      </Form>
    </DefaultLayout>
  );
};

export default CompanyForm;
