import React from 'react'
import { Content } from "antd/lib/layout/layout";

//UI
import BreadCrumb from "../breadcrumb";
import HeaderPage from "../headerpage";

const DefaultLayout = ( { children, breadcrumbItems, buttonData, title, breadcrumbDisable = false } ) => {

    const stylesContent = {
        paddingTop: 20, 
        paddingBottom: 20,
        paddingLeft: 10, 
        paddingRight: 10,
        marginTop: 10, 
        minHeight: 280, 
        borderRadius: 5
    }

    return (
        <>
            {
                !breadcrumbDisable && (
                    <BreadCrumb breadcrumbItems={breadcrumbItems} />
                )
            }
            
            <HeaderPage 
                title={title} 
                buttonData={buttonData} />

            <Content 
                className="site-layout-background"
                style={ stylesContent } >
                { children }
            </Content>
        </>
    )
}

export default DefaultLayout;
