import { React,useState } from "react";
import {

  ExceptionOutlined
} from "@ant-design/icons";
import SimpleLayout from "../../components/layouts/DefaultLayout";
const viewTitle = "Términos y Condiciones";
const Terminos = () => {
const breadcrumb=[
  { name: "Términos y Condiciones", to: "terminos", icon: <ExceptionOutlined /> }
];
  return (
    <SimpleLayout
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      children={
        <div style={{ textAlign: "center" }}>
        <h1></h1>
        <div style={{width:"100%",textAlign:"left"}}>
        <h2>INFORMACIÓN RELEVANTE</h2>
        <p>
        Es requisito necesario para la adquisición de los productos que se ofrecen en este sitio, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios así como la compra de nuestros productos implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. En todos los casos, para adquirir un producto, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos y definición de una contraseña.
        El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento, en caso de que se haya registrado y que sea necesario para la compra de alguno de nuestros productos. Promenal no asume la responsabilidad en caso de que entregue dicha clave a terceros.
        Todas las compras y transacciones que se lleven a cabo por medio de esta app, están sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación de los datos personales, validación de la forma de pago, validación del producto adquirido y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera una verificación por medio de correo electrónico.
        Los precios de los productos ofrecidos en esta Tienda Online es válido solamente en las compras realizadas en esta app.
        </p>
        <h2> 1. Aceptación de las Condiciones Generales y Particulares</h2>
        <p>
        La activación del botón de envío de la solicitud de contratación a través tanto de la app de PROMENAL o la aceptación de las condiciones expuestas telefónicamente, atribuye la condición de “Cliente” y expresa la aceptación plena y sin reservas de las Condiciones de Contratación de los Servicios solicitados en la versión que PROMENAL ponga a su disposición electrónicamente con carácter previo a la activación del botón de envío de la CONTRATACIÓN o al cierre de la venta telefónica. La última versión de las Condiciones de Contratación estará disponible en la dirección electrónica https://www.corporativopmn.com, así como de las Condiciones Particulares que pudieran aplicar por cada servicio que se encontrarán en la dirección electrónica https://www.corporativopmn.com.
        Antes de contratar cualquiera de los Servicios ofertados, lea con detenimiento las Condiciones de Contratación que regulan la prestación del Servicio que desea, incluyendo, las características técnicas, operativas y funcionales del Servicio, así como los costos aplicables en cada caso. Para cualquier información adicional o aclaración respecto al contenido de las Condiciones de Contratación, no dude en contactar con nuestro Servicio de Atención al Cliente antes de proceder a la Contratación al 01 800 0872 766.
        </p>
        <h2>2. Objeto y Ámbito de Contratación</h2>
        <h3>2.1 Objeto</h3>
        <p>
        Las Condiciones de Contratación tienen por objeto regular los términos contractuales para la prestación de los Servicios y la contraprestación debida por el Cliente a PROMENAL, así como la utilización del Servicio por éste. La prestación de los Servicios se llevará a cabo mediante la obtención, disposición, organización, empleo y gestión por parte de PROMENAL de los recursos técnicos, humanos y operativos necesarios al efecto y, siempre y en todo caso, como contraprestación a los precios vigentes en cada momento.
        </p>
        <h3>2.2 Ámbito</h3>
        <p>PROMENAL puede facilitar a sus Clientes el acceso a una amplia gama de recursos de asesoría de todos los Servicios ofertados por PROMENAL que se regirán por las Condiciones de Contratación aquí recogidas y por las Condiciones Particulares que en su caso se apliquen a cada uno de los Servicios en el clausulado general del contrato de prestación de Servicios Jurídicos Vigente.</p>
        <h2>3. Contratación</h2>
        <h3>3.1 Requisitos Subjetivos</h3>
        <p>Podrán contratar los Servicios aquellas personas físicas, mayores de edad, o personas morales que se dediquen a los servicios profesionales médicos y que cuenten con cédula profesional emitida por la SEP para poder ejercer dicha profesión u otorgar dichos servicios. En el caso de tratarse de una persona moral, la solicitud de los Servicios deberá ser llevada a cabo por un apoderado o un representante legal de la clínica, hospital o institución de que se trate, debidamente autorizado. En el caso de personas físicas, deberán ser mayores de edad y contratar en su propio nombre.
        </p>
        <h3 >3.2 Datos de la Solicitud</h3>
        <p>El solicitante es responsable de que tanto los datos incorporados en la Contratación a través de la app de PROMENAL, como aquellos otros datos relativos al proceso de registro, sean veraces y exactos, comprometiéndose a comunicar a PROMENAL, lo antes posible, todos aquellos cambios relativos a los mismos y, especialmente, los que aluden a la información necesaria para el buen desempeño y gestión de la prestación de los Servicios contratados, incluyendo, por ejemplo, la dirección de correo electrónico, el domicilio, y los datos de formas de pago. El Cliente podrá modificar los datos facilitados a través del Panel de Control de cliente en cualquier momento identificándose con su nombre de usuario y contraseña.</p>
        <h3>3.3 Denegación</h3>
        <p>PROMENAL se reserva el derecho de aceptar o rechazar cualquier Contratación, siempre y cuando: (a) compruebe que los datos facilitados son contrarios a la verdad y/o inexactos; (b) considere que la solicitud formulada: (i) no se ajusta a los términos recogidos en las Condiciones de Contratación y/o (ii) dañe o pueda dañar la imagen corporativa de PROMENAL o fuese contraria a los propósitos comerciales perseguidos por la misma; y/o (c) tenga pendiente de pago algún Servicio contratado con anterioridad con PROMENAL.</p>
        <h3>3.4 Aceptación</h3>
        <p>Se entenderá que una Contratación ha sido aceptada cuando el solicitante reciba una confirmación de la aceptación por parte de PROMENAL o cuando ésta active el Servicio contratado. La denegación de la prestación de los Servicios será realizada por correo electrónico o correo postal a las direcciones facilitadas en la Contratación, justificando el motivo de la misma. El solicitante cuya petición hubiera sido rechazada por PROMENAL podrá solicitar los servicios de nuevo, siempre y cuando, las razones que hubieren dado lugar a su no-admisión hubieren sido debidamente corregidas o eliminadas.</p>
        <h2>4. Observancia de los Términos y Condiciones</h2>
        <p>4.1 Estos Términos y Condiciones se rigen e interpretan de acuerdo con las leyes de México, sin referencia a sus disposiciones en materia de conflictos de ley. La Procuraduría Federal del Consumidor es competente por la vía administrativa para resolver las controversias que se susciten sobre el cumplimiento de este contrato en los términos y casos previstos por la Ley Federal de Protección al Consumidor.</p>
        <p>4.2. Cualquier y toda controversia sobre la validez o interpretación de estos Términos y Condiciones o su cumplimiento, será resuelta exclusivamente por un tribunal competente ubicado en la ciudad de México, CDMX, el cual constituirá la única sede para cualquier controversia. Si cualquier parte de estos Términos y Condiciones deviene ilegal, inválida o no aplicable, dicha parte será separada y no afectará la validez y cumplimiento de las disposiciones restantes. PROMENAL puede modificar estos Términos y Condiciones en cualquier momento mediante la actualización de esta publicación.</p>
        <p>4.3. PROMENAL puede terminar este contrato anticipadamente en cualquier momento sin previo aviso y por cualquier motivo, sin responsabilidad y sin necesidad de resolución judicial previa, si se incurre en alguna cláusula de rescisión contemplada en el contrato de servicios jurídicos pactado.</p>
        <h2>5. Corrección y aplicación de pagos</h2>
        <p>Promenal se compromete a que en el momento de ser notificado por parte del cliente vía correo electrónico, de cualquier anomalía en sus pagos que no se vean reflejados o se encuentren aplicados incorrectamente, realizará las acciones necesarias para la correcta aplicación de los pagos y sean reflejados en la cuenta del cliente de inmediato, para lo cual, el cliente deberá soportar su reclamación con los documentos que amparen el pago correspondiente, que sean claros para su validación y aplicación.</p>
        <h2>6. Facturación</h2>
        <p>El Cliente recibirá una o varias facturas correspondientes a los Servicios contratados y que serán emitidas de acuerdo a las reglamentaciones del SAT. El Cliente proporcionará datos de uno o varios métodos de pago aceptados por PROMENAL. Si el Cliente proporciona información de tarjeta de crédito o cuenta bancaria para débito directo nos autoriza a efectuar cargos en dicha tarjeta de crédito o cuenta bancaria por todos los Servicios contratados indicados en la Orden de Compra o Formulario Web de Compra por el periodo de suscripción contratado inicialmente y por cualquier renovación derivada del mismo. Dichos cargos se realizarán por anticipado, ya sea mensual o bianual o cualquier otra frecuencia indicada en la Orden de Compra aceptada por el cliente y usando cualquiera de los métodos de pago suministrados por el Cliente.</p>
        <h2>7. ContraCargos</h2>
        <p>PROMENAL se compromete a revisar hasta la resolución del asunto, cualquier cargo doble o erróneo aplicado al cliente por cuestiones de sistema o errores de operación, lo cual, el cliente deberá solicitar vía correo electrónico mediante la revisión y devolución de los montos aplicados, mismos que serán devueltos a la brevedad posible, a la cuenta del cliente de aquellos movimientos que aplique la devolución y sean comprobados fehacientemente.</p>
        <h2>8.- Devoluciones</h2>
        <p>En caso de que el cliente desee cancelar el servicio, NO aplicará devolución económica alguna de las cantidades pagadas. Salvo en los casos en que en la cancelación se compruebe algún contra cargo de pago o duplicidad de pagos..</p>
        <h2>9. Cancelación</h2>
        <p>Promenal o el cliente podrán dar por terminado el contrato de servicios jurídicos de común acuerdo en cualquier momento, sin que exista penalización económica alguna para cualquiera de las partes.</p>
        <h2>10. Especificidad</h2>
        <p>Para temas específicos del contrato (servicio), así como los alcances del mismo, ambas partes se remitirán al contrato de prestación de Servicios Jurídicos materia de los presentes términos y condiciones generales informativos, en la inteligencia de que estos términos y condiciones no suplen al contrato ni parcial, ni totalmente en alguna de sus partes.</p>
        </div>
          </div>
      }
    >
    </SimpleLayout>
  );
};
export default Terminos;
