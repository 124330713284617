import { 
  Row, 
  Col, 
  Card 
} from 'antd';
import { 
  UserOutlined,
  CommentOutlined,
  MailOutlined,
  BookOutlined,
  ExceptionOutlined,
  CodepenOutlined


} from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import DefaultLayout from '../../components/layouts/DefaultLayout';
import "../styles/Home.less";
import {auth} from "../../services/firebase"
import { React, useState, useEffect } from "react";
import { doc,getDoc} from "@firebase/firestore";
import { firestore } from "../../services/firebase";
const Home = () => {
    const history = useHistory();
    const { Meta } = Card;
    const [user, setUser] = useState();
    const [cards, setCards] = useState([]);
    const [mounted, setMounted] = useState(false);
    const currenUid=auth.currentUser.uid;
    useEffect(() => {
    getDoc(doc(firestore,"users",currenUid)).then(doc=>{
      let data=doc.data();
      setUser(data);
      console.log(data);
      if(data.role=="admin"){
        setCards([
          {
            id: 1,
            title: 'Usuarios',
            icon: <UserOutlined />, 
            desc:' ',
            url: '/admin/usuarios', 
          },
          {
            id: 2,
            title: 'Mensajes',
            icon: <CommentOutlined />, 
            desc:' ',
            url: '/mensajes', 
          },

          {
            id: 3,
            title: 'Emails Capturados',
            icon: < MailOutlined />, 
            desc:' ',
            url: '/emails', 
          },
          {
            id: 4,
            title: 'Paquetes',
            icon: < CodepenOutlined />, 
            desc:' ',
            url: '/admin/paquetes', 
          },
    
        ]);
      }
      if(data.role=="client"){
        setCards([
          {
            id: 1,
            title: 'Perfil',
            icon: <UserOutlined />, 
            desc:' ',
            url: '/perfil', 
          },
          {
            id: 2,
            title: 'Póliza',
            icon: <BookOutlined />, 
            desc:' ',
            url: '/poliza', 
          },
          {
            id: 3,
            title: 'Terminos y Condiciones',
            icon: < ExceptionOutlined />, 
            desc:' ',
            url: '/terminos', 
          },
        ]);
      }
      setMounted(true);
      console.log(cards);
    })
    }, []);
  return (
    <DefaultLayout className="site-card-wrapper" title="Bienvenido">
      <Row gutter={{ xs: 2, sm: 1, md: 2, lg: 10, xxl: 10}} >
        { cards ? cards.map( item  => (
          <Col
            key={item.id} 
            onClick={ ()=> history.push(item.url) }
            className="gutter-row" 
            xs={{ span: 24, offset: 0 }} 
            sm={{ span: 12, offset: 0 }} 
            md={{ span: 12, offset: 0 }} 
            lg={{ span: 6, offset: 0 }}  
            xxl={{ span: 6, offset: 0 }}
          >
            <Card
              hoverable
              style={{ width: '100%' }}
              cover={item.icon}
            >
              <Meta className="av-text-center" title={item.title} description={item.desc}/>
            </Card>
          </Col>
        ) ):null }

      </Row>
    </DefaultLayout>
  );
};

export default Home;