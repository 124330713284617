import React from "react";
import { Form, Input, Button, Row, Col, Card} from "antd";
import { useAuth } from "../../hooks";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const SignIn = () => {
  const { signIn, sessionLoading } = useAuth();

  const onFinish = (values) => {
    const { email, password } = values;
    signIn(email, password);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Row  style={{paddingTop: "10%"}}> 
        <Col span={6} md={12} lg={10} xs={22} offset={2} style={{ textAlign: "center", marginBottom: 25 }}>
          {<img src="img/logo192.png" alt="logo" width={450} style={{marginTop: 0}} /> }
        </Col>
        <Col span={6} md={6} lg={6} xs={22}  offset={1}>
          <Card
            style={{
              minWidth: 450,
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)" 
            }}
            title="INICIA SESIÓN"
            headStyle={{
              color: "#3E9BBC"
            }}
          >
            <Form {...layout} 
              name="basic"
              initialValues={{
                email: "",
                password: "",
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Correo electrónico"
                name="email"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Favor de ingresar su correo electrónico.",
                    type: "email"
                  },
                ]}
              >
                <Input type="email"/>
              </Form.Item>
              <Form.Item
                label="Contraseña"
                name="password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Favor de ingresar su contraseña.",
                  },
                ]}
              >
                <Input.Password/>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={sessionLoading}> 
                  Iniciar sesión
                </Button>
              </Form.Item>  
            </Form>
            <a href={"signup"} style={{fontSize:20}}>No tienes cuenta? Registrate Hoy!</a>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
        {/* {
          showError
          ?
             <Alert
              message="¡Error!"
              description="Error al iniciar sesión intentelo de nuevo."
              type="error"
              closable
              onClose={() => {}}
            />
          :
            null
        } */}
        </Col>
      </Row>
    </div>
  );
};

export default SignIn;
