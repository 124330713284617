import { Col, Layout, Row } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import React from "react";
import "../../styles/AuthLayout.less";

const AuthLayout = ({ children }) => {
  return (
    <Layout  style={{height: "100vh",marginBottom:0}}>
      <Header style={{backgroundColor:"#025850",color:"white"}} >
        <Row  type="flex"  justify="space-between">
          <Col>
            <a href={"https://promenal-sonora.web.app/"} style={{color:"white",fontSize:20}}>Promenal Portal Web</a>

          </Col>
          <Col>
          <a href={"/"} style={{color:"white",fontSize:20}}>Inicio de sesión</a>
          </Col>
          
        </Row>
      </Header>
      <Content  style={{marginBottom:0,backgroundColor:"white",width: "100wh"}}>{children}</Content>


    </Layout>
  );
};

export default AuthLayout;
