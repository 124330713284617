import React, { useEffect, useState } from 'react';
import ViewLoading from '../../components/ViewLoading';
import { useAuth } from "../../hooks";
import { auth,firestore } from "../../services/firebase";
import { getDoc , doc,updateDoc } from "firebase/firestore";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin,DatePicker,InputNumber,Switch } from 'antd'
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from "moment";

const roles = {
  1: "SOPORTE", 
  2: "ADMIN",
  3: "OPERADOR"
};

const ProfileView = () => {
  
  const { user } = useAuth();
  const [form] = Form.useForm();
  const [model, setModel] = useState({});
  const [mounted, setMounted] = useState(false);

  const [pagos, setPagos] = useState("")
  const [saveLoading, setSaveLoading] = useState(false)
  const {Option} = Select

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const currentUser=auth.currentUser.uid;
  const [profile, setProfile] = useState({
    nombre: "",
    correo: "",
    rol: "",
    password: "",
    confirmPassword: "",
    uid: ""
  });

  useEffect(() => {
      console.log(currentUser);
      const ref= doc(firestore,"users",currentUser)
       getDoc(ref).then(docSnap=>{
        if (docSnap.exists() && mounted===false) {
          const data=docSnap.data();
          setModel(data);
          if ( model) {
            try{
              setPagos(model.numeroPagos);
            }catch(error){console.log(error)}
            try{
              model.primerpagoFecha=moment(model?.primerpagoFecha?.toDate());
              
            }
            catch(error){
              console.log("error-catch-model",error);
            }
            try{
              model.segundopagoFecha=moment(model?.segundopagoFecha?.toDate());
              
            }
            catch(error){
              console.log("error-catch-model",error);
            }
            try{
              model.tercerpagoFecha=moment(model?.tercerpagoFecha?.toDate());
              
            }
            catch(error){
              console.log("error-catch-model",error);
            }
            try{
              model.fechaTerminacion=moment(model?.fechaTerminacion?.toDate());
              
            }
            catch(error){
              console.log("error-catch-model",error);
            }
            form.setFieldsValue(model);
            setLoading(false);
          }
          setMounted(true);

        } else {
          console.log("No such document!");
        }
      })
  }, [model, form]); 
  const onSubmit = async () => {
    try{
      try{
        await form.validateFields()
      }catch{
        return
      } 
      let data = form.getFieldsValue(true);
          if(data?.primerpagoFecha){
            try{
              let d1=data?.primerpagoFecha?._d;
              delete data["primerpagoFecha"];
              data.primerpagoFecha=d1;
              }
              catch(error){console.log(error);}
          }
          if(data?.segundopagoFecha){
            try{
              let d2=data?.segundopagoFecha?._d;

              delete data["segundopagoFecha"];

              data.segundopagoFecha=d2;
              }
              catch(error){console.log(error);}
          }
          if(data?.tercerpagoFecha){
            try{
              let d3=data?.tercerpagoFecha?._d;

              delete data["tercerpagoFecha"];

              data.tercerpagoFecha=d3;
              }
              catch(error){console.log(error);}
          }

          if(data?.fechaTerminacion){
            try{
              let d4=data?.fechaTerminacion?._d;

              delete data["fechaTerminacion"];

              data.fechaTerminacion =d4;
              }
              catch(error){console.log(error);}
          }
      data.user=currentUser;
      if (true){
          updateDoc(doc(firestore,"users",currentUser),data).then(()=>{
          messageSuccess();
          });
      }
    } catch(error){
      console.log("error-catch",error)
    } finally{
      setSaveLoading(false)
    }
  };


  if(loading) return <ViewLoading />;


  function onChange(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
  }
  function onOk(value) {
    console.log('onOk: ', value);
  }
  const messageSuccess = () => {
    let secondsToGo = 2;
    const modal = Modal.success({
      title: "Datos actualizados correctamente",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { style: { display: 'none' } }
    });
    const timer = setInterval(() => {secondsToGo -= 1}, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }
  return (
    <div>
      <Row style={{marginTop: 20}}>
        <Col span={8}>
          <h1 level={2}>Perfil</h1>
        </Col>
      </Row>
      <Form
        component="div"
        form={form}
        layout="vertical"
      >
        <h1 style={{fontSize:30}}>Datos Fiscales</h1>
        <Row  gutter={[16, 0]}>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="nombre"
              label="Nombre"
              hasFeedback
              rules={[
                {
                  required:true,
                  message:"Esta campo es obligatorio."
                }
              ]}
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Nombre"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="apellidoPaterno"
              label="Apellido Paterno"
              hasFeedback
              rules={[
                {
                  required:true,
                  message:"Esta campo es obligatorio."
                }
              ]}
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Apellido Paterno"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="apellidoMaterno"
              label="Apellido Materno"
              hasFeedback
              rules={[
                {
                  required:true,
                  message:"Esta campo es obligatorio."
                }
              ]}
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Apellido Materno"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="rfc"
              label="R.F.C."
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el R.F.C."
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="curp"
              label="CURP"
              hasFeedback
     
            >
              <Input 
                maxLength={14}
                placeholder="Escribe el CURP"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="cedulaProfessional"
              label="Cédula Profesional"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Cédula Professional"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="especialidad"
              label="Especialidad"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Especialidad"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="cedulaEspecialidad"
              label="Cédula de Especialidad"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Cédula de Especialidad"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="calle"
              label="Calle"
              hasFeedback
     

            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Calle"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroExterior"
              label="Numero Exterior"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero exterior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroInterior"
              label="Numero Interior"
              hasFeedback
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero interior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="colonia"
              label="Colonia"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la colonia"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="municipio"
              label="Delegación o municipio"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el municipio"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="estado"
              label="Estado"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el estado"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="codigoPostal"
              label="Código Postal"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Código Postal"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="telefono"
              label="Teléfono "
              required
              hasFeedback
     
            >
              <Input 
                maxLength={14}
                placeholder="Escribe el teléfono"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="email"
              label="Correo electrónico"
              hasFeedback
              rules={[{ type: 'email' }]}
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el correo electrónico"
              />
            </Form.Item>
          </Col>
        </Row>
        <h1 style={{fontSize:30, paddingTop:10}}>Consultorio</h1>
        <Row gutter={[16, 0]}>
        <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="calleConsultorio"
              label="Calle"
              hasFeedback
     

            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Calle"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroExteriorConsultorio"
              label="Numero Exterior"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero exterior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroInteriorConsultorio"
              label="Numero Interior"
              hasFeedback
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero interior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="coloniaConsultorio"
              label="Colonia"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la colonia"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="municipioConsultorio"
              label="Delegación o municipio"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el municipio"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="estadoConsultorio"
              label="Estado"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el estado"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="codigoPostalConsultorio"
              label="Código Postal"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Código Postal"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="telefonoConsultorio"
              label="Teléfono "
              hasFeedback
     
            >
              <Input 
                maxLength={14}
                placeholder="Escribe el teléfono"
              />
            </Form.Item>
          </Col>
              <Col sm={12} md={8}>
                <Form.Item
                  name="password"
                  label="Contraseña"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, escriba la contraseña!',
                    },
                    {
                      min:8,
                      message: 'Contraseña debe tener minimo 8 caracteres',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
                 
        </Row>
        <h1 style={{fontSize:30, paddingTop:10}}>Hospital</h1>
        <Row gutter={[16, 0]}>
        <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="nombreHospital"
              label="Nombre"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Nombre"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="servicio"
              label="Servicio"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el servicio"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="calleHospital"
              label="Calle"
              hasFeedback
     

            >
              <Input 
                maxLength={500}
                placeholder="Escribe la Calle"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroExteriorHospital"
              label="Numero Exterior"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero exterior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="numeroInteriorHospital"
              label="Numero Interior"
              hasFeedback
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el numero interior"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="coloniaHospital"
              label="Colonia"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe la colonia"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="municipioHospital"
              label="Delegación o municipio"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el municipio"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="estadoHospital"
              label="Estado"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el estado"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="codigoPostalHospital"
              label="Código Postal"
              hasFeedback
     
            >
              <Input 
                maxLength={500}
                placeholder="Escribe el Código Postal"
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              name="telefonoHospital"
              label="Teléfono "
              hasFeedback
     
            >
              <Input 
                maxLength={14}
                placeholder="Escribe el teléfono"
              />
            </Form.Item>
          </Col>
        </Row>
        <h1 style={{fontSize:30, paddingTop:10}}>Datos de Pago</h1>
        <Row gutter={[16, 0]}>
        <Col sm={24} md={4}>
          <Form.Item 
            style={{ marginBottom: '0px' }}
            required
            hasFeedback
            name="fechaTerminacion"
            label= "Terminacion"

          >
            <DatePicker  
            disabled
          locale={locale}
            style={{ width:"100%" }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                
                    onChange={onChange}
                    onOk={onOk}
                    placeholder="Fecha"
            />
          </Form.Item>
          
        </Col>
         <Col sm={12} md={4}>
          <Form.Item 
              style={{ marginBottom: '0px' ,marginRight: '20px'}}
              required
              name="paquete"
              label="Paquete"
              hasFeedback
            
   
            >
              <Select
                disabled
                defaultValue={model.paquete}
                showSearch  
                style={{ width: "100%" }}
                listItemHeight={10} 
                listHeight={250}
                placeholder="Seleccione el paquete"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                       <Option key={"VIP"} value={"VIP"}>{"VIP"}</Option>
                       <Option key={"PREMIER"} value={"PREMIER"}>{"PREMIER"}</Option>
                       <Option key={"CLASICO"} value={"CLASICO"}>{"CLÁSICO"}</Option>
              </Select>
            </Form.Item>
          </Col>
         <Col sm={12} md={4}>
          <Form.Item 
              style={{ marginBottom: '0px' ,marginRight: '20px'}}
              required
              name="metodoPago"
              label="Metodo de Pago"
              hasFeedback

            >
              <Select
                defaultValue={model.metodoPago}
                showSearch  
                disabled
                style={{ width: "100%" }}
                listItemHeight={10} 
                listHeight={250}
                placeholder="Seleccione el metodo de pago"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                       <Option key={"CHEQUE"} value={"CHEQUE"}>{"CHEQUE"}</Option>
                       <Option key={"EFECTIVO"} value={"EFECTIVO"}>{"EFECTIVO"}</Option>
                       <Option key={"CREDITO"} value={"CREDITO"}>{"CREDITO"}</Option>
                       <Option key={"TRANSFERENCIA"} value={"TRANSFERENCIA"}>{"TRANSFERENCIA"}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={12} md={3}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="deposito"
              label="Deposito"
              hasFeedback

            >
              <InputNumber
              disabled
              />
            </Form.Item>
          </Col> 

   
          
</Row>
<Row style={{paddingTop:20}} gutter={[16, 0]}>
{pagos>=1?(
          <Col sm={24} md={4}>
          <Form.Item 
            style={{ marginBottom: '0px' }}
            required
            hasFeedback
            name="primerpagoFecha"
            label= "Fecha Primer Pago"

          >
            <DatePicker 
            disabled 
          locale={locale}
            style={{ width:"100%" }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                
                    onChange={onChange}
                    onOk={onOk}
                    placeholder="Fecha"
            />
          </Form.Item>
          
        </Col>
        ):null}
        {pagos>=1?(
                  <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="primerpago"
                    label="Primer Pago"
                    hasFeedback

                  >
                    <InputNumber
                    disabled
                    style={{width:"100%"}}
                      min={1}
                      initalValue={1}
                    />
                  </Form.Item>
                </Col>
      ):null}
              {pagos>=1?(
                  <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="primerPagado"
                    label="Pagado"
                    hasFeedback

                  >
                    <Switch
                    disabled
                    defaultChecked={model.primerPagado}
                    style={{width:"70%"}}
                    />
                  </Form.Item>
                </Col>
      ):null}
      </Row>
      <Row  gutter={[16,0]}>
{pagos>=2?(
          <Col sm={24} md={4}>
          <Form.Item 
            style={{ marginBottom: '0px' }}
            required
            hasFeedback
            name="segundopagoFecha"
            label= "Fecha Segundo Pago"

          >
            <DatePicker  
            disabled
          locale={locale}
            style={{ width:"100%" }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                
                    onChange={onChange}
                    onOk={onOk}
                    placeholder="Fecha"
            />
          </Form.Item>
          
        </Col>
      ):null}
{pagos>=2?(
        <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="segundopago"
                    label="Segundo Pago"
                    hasFeedback
             
                  >
                    <InputNumber
                    disabled
                      style={{width:"100%"}}
                      min={1}
                      initalValue={1}
                    />
                  </Form.Item>
                </Col>
               
               ):null}
                             {pagos>=2?(
                  <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="segundoPagado"
                    label="Pagado"
                    hasFeedback

                  >
                    <Switch
                    disabled
                    defaultChecked={model.segundoPagado}
                    style={{width:"70%"}}
                    />
                  </Form.Item>
                </Col>
      ):null}
  </Row>

<Row gutter={[16,0]}>
{pagos>=3?(
        <Col sm={24} md={4}>
          <Form.Item 
            style={{ marginBottom: '0px' }}
            required
            hasFeedback
            name="tercerpagoFecha"
            label= "Fecha Tercer Pago"
  
          >
            <DatePicker  
            
            disabled
          locale={locale}
            style={{ width:"100%" }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                
                    onChange={onChange}
                    onOk={onOk}
                    placeholder="Fecha"
            />
          </Form.Item>
          
        </Col>
):null}

{pagos>=3?(

                <Col sm={24} md={3}>
                <Form.Item 
                  style={{ marginBottom: '0px' }}
                  required
                  name="tercerpago"
                  label="Tercer Pago"
                  hasFeedback
        
                >
                  <InputNumber
                  disabled
                    style={{width:"100%"}}
                    min={1}
                    initalValue={1}
                  />
                </Form.Item>
              </Col>
):null}
              {pagos>=3?(
                  <Col sm={24} md={3}>
                  <Form.Item 
                    style={{ marginBottom: '0px' }}
                    required
                    name="tercerPagado"
                    label="Pagado"
                    hasFeedback
                    
                  >
                    <Switch
                    disabled
                    defaultChecked={model.tercerPagado}
                    style={{width:"70%"}}
                    />
                  </Form.Item>
                </Col>
      ):null}
  </Row>
        <Row gutter={[16, 0]}  style={{ marginTop: '10px' }}>
          <Col sm={24}>
            <Form.Item>
              <Button 
                type="primary" 
                onClick={onSubmit}
                loading={saveLoading}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row> 
      </Form>
    </div>
  )
}

export default ProfileView;