import React from 'react';
import {  Row, Col, Button, Typography } from 'antd';
const { Title } = Typography;

const HeaderPage = ( {title, buttonData} ) => {   
    return (
        <Row justify="space-between">
            <Col>
                <Title level={4}>{title || "Página sin titulo"}</Title>
            </Col>
            <Col>
                {Boolean(buttonData) && (
                    <Button
                    onClick={
                        buttonData.to
                        ? buttonData.to
                        : undefined
                    }
                    {...buttonData.props}
                    >
                    {buttonData.text} {buttonData.icon}
                    </Button>
                )}
            </Col>
        </Row>
    )
}
export default HeaderPage;