import { React,useState,useEffect } from "react";
import {  Descriptions, Table,  Modal, Typography } from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  CommentOutlined
} from "@ant-design/icons";
import { red } from "@ant-design/colors";
import { DropdownOverlayButton } from "../../components";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import { useHistory } from "react-router-dom"
import { onSnapshot,doc,query,collection } from "@firebase/firestore";
import { firestore } from "../../services/firebase";
import {  deleteDoc} from "firebase/firestore";
const viewTitle = "Administración de mensajes recibidos";
const searchPlaceholder = "Búsqueda, ejemplo: mensaje de la mensaje";
const emptyText = "No hay mensajes recibidos...";
const mapStyles = {
  height: "100%",
  width:"100%"}
const Mensajes = ({ route }) => {
  const defaultProps = {
    center: {
      lat: 29.0730,
      lng: -110.9559
    },
    zoom: 15
  };
  const [searchLoading, setSearchLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [modelsLoading, setModelsLoading] = useState(true);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    const q = query(collection(firestore, "messages"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const mensajes = [];
      querySnapshot.forEach((doc) => {
          mensajes.push(doc.data());
      });
      console.log(mensajes);
      var sortedObjs = mensajes.sort((a, b) =>  - b.fecha-a.fecha)
      setModels(sortedObjs);
      setModelsLoading(false);
    });
  }, []);
  const renderSimple = (prop) => prop || <i>--</i>;
  const columns = [
    {
      title: "Nombre",
      width: 150,
      dataIndex: "nombre",
      key: "nombre",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Correo Electronico",
      width: 150,
      dataIndex: "email",
      key: "email",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Mensaje",
      width: 150,
      dataIndex: "mensaje",
      key: "mensaje",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Fecha",
      width: 150,
      dataIndex: "date",
      key: "date",
      fixed: "left",
      render:  (date)=>date ? date?.toDate().toLocaleDateString('es-mx')+" "+date?.toDate().toLocaleTimeString('es-mx') : "Sin asignar"
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "center",
      width: 100,
      render: (text, row) => (
        <DropdownOverlayButton
          options={[

            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => modalDelete(row),
              styleProps: {
                color: red[4],
              },
            },
          ]}
        />
      ),
    },

  ];
  const ContentModal = ({row}) => {
    return (
      <div>
        <Descriptions
          title="Información del mensaje"
          size="small"
        >
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Título" span={3}>{row?.titulo}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Descripción">{row?.descripcion}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Fecha de Creacion">{row?.fecha?.toDate().toLocaleDateString('es-mx')+" "+row?.fecha?.toDate().toLocaleTimeString('es-mx')}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Activo?">{row?.activo?"Activado":"Desactivado"}</Descriptions.Item>
        
        </Descriptions>
      </div>
    )
  }
  const modalInfo = (v) => {
    Modal.confirm({
      title: "",
      icon: null,
      content: <ContentModal row={v}/>,
      okText: "Ok",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { primary: true },
      closable: true,
      width:1000
    });
  };
  const modalDelete = (v) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar el mensaje  "${v.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        try {
          const ref= doc(firestore,"mensajes",v.id)
          await deleteDoc(ref);
        } catch (error) {
          console.log(error);
        }
      },
    });
  };
  const onSearchClicked = async (v) => {
    if(v==""){
      if(reset){setReset(false)}else{setReset(true)}
    };
    setSearchLoading(true);
    v=v.toLowerCase();
    var newArray = models.filter(function (el) {
      return el.titulo.toLowerCase().includes(v) || el.descripcion.toLowerCase().includes(v)  ;
    });
    setModels(newArray);
    
    setTimeout(() => setSearchLoading(false), 1500);
  };
const breadcrumb=[
  { name: "Mensajes", to: "mensajes", icon: <CommentOutlined /> }
];
  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearchClicked}
      emptyText={emptyText}
      children={
        <Table
          columns={columns}
          dataSource={models}
          rowKey="id"
          loading={modelsLoading}
        />
      }
    >
    </SimpleTableLayout>
  );
};
export default Mensajes;
