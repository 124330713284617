import React from "react"
import { Row ,Card, Button,Divider} from 'antd';

const { Meta } = Card;
const CLASICO = (props) => (
  <div style={{ textAlign: "center" }}>
  <Row  type="flex" style={{alignItems: 'center',justifyContent:"center" }}>
    <div style={{paddingLeft:25,paddingRight:25,paddingTop:30}}>
      <Card
        onClick={()=>{}}
        hoverable
        style={{ width: 550}}
        cover={<img src={"img/clasico.png"}></img>}
      >
        <Meta title="CLÁSICO" description="$12,500 MXN"/>

      </Card>
    </div>
    <div style={{paddingLeft:25,paddingRight:25,paddingTop:30}}>
      <Card
        onClick={()=>{
        }}
        hoverable
        style={{ width: 550}}
        cover={    
          <div style={{paddingTop:20 }} >
          <h1 style={{fontWeight:"bold",fontSize: '35px', color: 'black',paddingTop:20 }}>PAQUETE CLÁSICO</h1>
          {props.active ?
(
  <div>
<h3 style={{textDecoration:"underline",paddingTop:20}}>{"Fecha de Terminacion: "+props?.date?.toDate().toLocaleDateString('es-mx')}</h3>

  <Button  style={{height:70,width:"75%",backgroundColor:"green",color:"white",fontSize:35}} type="secondary">POLIZA ACTIVA</Button>

  </div>
):
(
  <div>
<h3 style={{textDecoration:"underline",paddingTop:20}}>$12,500 MXN</h3>
  <Button  style={{width:"75%"}} type="primary">Comprar</Button>
  </div>
)}
          <Divider/>
          <h1 style={{fontWeight:"bold",fontSize: '20px', color: 'black',paddingTop:35}}>Descripción </h1>
          <h3 style={{fontSize: '15px', color: 'black',paddingTop:5}}>Cobertura $1,500,000.00 MXN</h3>
          <div style={{textAlign:"left"}}>
          <ul>
          <li> Asesoría especializada en medicina legal telefónica legal las 24 horas los 365 días del año</li>
          <li>Patrocinio ilimitado de abogados en medicina legal</li>
          <li>Representación legal en vías: civil, penal y administrativa ilimitada</li>
          <li>Capacitación en manejo de  conflictos médico-legales</li>
          <li>Gastos judiciales, cubiertos ilimitados</li>
          <li>Peritajes y opiniones médico técnicas ilimitadas</li>
          <li>Capacitación expediente clínico desde el punto de vista legal</li>
          <li>Actualización en los cambios en normas y leyes que rigen el ejercicio médico</li>
          <li>Asesoría legal a familiares directos ilimitada</li>
          <li>Fianzas preliberadas para tramitar libertad provisional en caso de una orden de aprehensión, según la póliza contratada con la compañía aseguradora para tal efecto</li>
          <li>Reparación del DAÑO, por sentencia ejecutoriada, mediante la RC contratada con la compañía aseguradora para tal efecto.</li>
          <li>Protección a Nivel Nacional</li>
          </ul> 
          </div>
        </div>   
      }
      >
      </Card>
    </div>
  </Row>
</div>
)
export default CLASICO
