import React from "react";
import {
  HomeOutlined,
  UserOutlined,
  TeamOutlined,
  CommentOutlined,
  CodepenOutlined,
  MailOutlined,ExceptionOutlined,BookOutlined
} from "@ant-design/icons";
import HomeView from "../views/home/Home";
import UsuariosView  from "../views/Usuarios/Usuarios";
import UsuarioForm from "../views/Usuarios/UsuarioForm";
import ProfileView from "../views/profile/ProfileView";
import NotAuthorizedView from "../views/shared/NotAuthorized";
import NotFoundView from "../views/shared/NotFound";
import { signOut } from "@firebase/auth";
import { auth } from "../services/firebase";
import Mensajes from "../views/Mensajes/Mensajes";
import Emails from "../views/Emails/Emails";
import Terminos from "../views/Terminos/Terminos";
import PolizaView from "../views/Poliza/Poliza";
import PaquetesView from "../views/Paquetes/Paquetes";
import PaquetesForm from "../views/Paquetes/PaqueteForm";


const singOutRoute = () => {   
  signOut( auth )
  return( "Cargando..." )
}
const sharedRoutes = [

  {
    path: "/no-autorizado",
    component: NotAuthorizedView,
  },
  {
    path: "/salir",
    component: singOutRoute,
  },
  {
    path: "*",
    component: NotFoundView,
  },
];
const admRoutes = [
  {
    layout: "dashboard",
    path: "/",
    name: "Inicio",
    icon: <HomeOutlined />,
    sidebar: "single",
    component: HomeView,
  },

  {
    layout: "dashboard",
    path: "/mensajes",
    name: "Mensajes",
    icon: <CommentOutlined />,
    sidebar: "single",
    component: Mensajes,
  },
  {
    layout: "dashboard",
    path: "/emails",
    name: "Emails Capturados",
    icon: <MailOutlined />,
    sidebar: "single",
    component: Emails,
  },
{
    layout: "dashboard",
    path: "/admin",
    name: "Administración",
    icon: <TeamOutlined/>,
    sidebar: "collapse",
    routes:[
      {
        layout: "dashboard",
        path: "/usuarios",
        name: "Usuarios",
        icon: <UserOutlined />,
        sidebar: "single",
        component: UsuariosView,
        routes:[
          {
            path: "/",
            component: UsuariosView,
           },
           {
             path: "/nueva",
           component: UsuarioForm,
          },
           {
            path: "/editar",
             component: UsuarioForm,
         },
        ]
      },
      {
        layout: "dashboard",
        path: "/paquetes",
        name: "Paquetes",
        icon: <CodepenOutlined />,
        sidebar: "single",
        routes:[
          {
            path: "/",
            component: PaquetesView,
           },
           {
             path: "/nueva",
           component: PaquetesForm,
          },
           {
            path: "/editar",
             component: PaquetesForm,
         },
        ]
      },
    ],
  },
  {
    layout: "dashboard",
    path: "/terminos",
    name: "Términos y Condiciones",
    icon: <ExceptionOutlined />,
    sidebar: "single",
    component: Terminos,
  },
];
const clientRoutes = [
  {
    layout: "dashboard",
    path: "/",
    name: "Inicio",
    icon: <HomeOutlined />,
    sidebar: "single",
    component: HomeView,
  },
  {
    layout: "dashboard",
    path: "/perfil",
    name: "Perfil",
    icon: <UserOutlined />,
    sidebar: "single",
    component: ProfileView,
  },
  {
    layout: "dashboard",
    path: "/poliza",
    name: "Póliza",
    icon: <BookOutlined />,
    sidebar: "single",
    component: PolizaView,
  },
  {
    layout: "dashboard",
    path: "/terminos",
    name: "Términos y Condiciones",
    icon: <ExceptionOutlined />,
    sidebar: "single",
    component: Terminos,
  },
];

const dashboardRoutes=[...clientRoutes,...sharedRoutes];
const adminRoutes=[...admRoutes,...sharedRoutes];

export { dashboardRoutes,adminRoutes };