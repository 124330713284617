import React from "react";
import {Modal} from "antd";
import { firestore } from "../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { emptyRequest, getRequest } from "../constants/requests";
import { useHttp } from "./useHttp";
import { useApp } from "./useApp";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut as signOutFirebase } from 'firebase/auth';
import { auth } from "../services/firebase";
import {  doc,setDoc} from "firebase/firestore";

const AuthContext = React.createContext();
const empty = emptyRequest();

export function AuthProvider(props) {
  const { token } = useApp();
  const [session, sessionLoading, sessionError] = useAuthState(auth);
  const [agendaRequest, setAgendaRequest] = React.useState(empty);
  const [agendaResponse, agendaResponseLoading, agendaError] = useHttp(agendaRequest);
  const messageError = (title) => {
    let secondsToGo = 2;
    const modal = Modal.error({
      title:title,
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { style: { display: 'none' } }
    });
    const timer = setInterval(() => {secondsToGo -= 1}, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }
  const signIn = React.useCallback(async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      messageError("Credenciales invalidas");
      console.error(e);
      return(e);
    }
  }, []);

  const signUp = React.useCallback(async (email, password) => {
    try {
      let data={};
      createUserWithEmailAndPassword(auth,email,password).then( user=>
        {
         let uid= user.user.uid;
         data.id=uid;
         data.role="client";
         data.email=email;
          setDoc(doc(firestore, "users",uid),data)
        }
          ).catch(error=>{
            messageError("Credenciales invalidas");
            console.error(error);
            return(error);
          });
    } catch (e) {
      messageError("Credenciales invalidas");
      console.error(e);
      return(e);
    }
  }, []);

  const signOut = React.useCallback(async () => {
    try {
      await signOutFirebase( auth );
      setAgendaRequest(empty);
    } catch (e) {
      console.error(e);
    }
  }, []);

  React.useEffect(() => {
    if (token) {
      const agendaReq = getRequest("servicio/perfil");
      setAgendaRequest(() => agendaReq);
    } else {
      setAgendaRequest(empty);
    }
  }, [token]);

  const memData = React.useMemo(() => {
    return {
      session,
      sessionLoading,
      user:
        agendaResponse ? 
            {
              ...agendaResponse,
            }
          : null,
      userLoading: agendaResponseLoading,
      userError: agendaError || sessionError,
      signIn,
      signOut,
      signUp
    };
  }, [
    session,
    sessionLoading,
    sessionError,
    agendaResponse,
    agendaResponseLoading,
    agendaError,
    signIn,
    signOut,
    signUp
  ]);

  return <AuthContext.Provider value={memData} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: auth context not defined.";
  }
  return context;
}