import React from "react";
import useSound from "use-sound";
import boopSfx from "../sounds/notification_decorative-01.wav";
import { firestore } from "../services/firebase";
// import ReactNotifications from "react-notifications-component";
// import { store } from "react-notifications-component";
import { notification } from 'antd';

import { updateDoc , onSnapshot,doc,query,collection,where } from "@firebase/firestore";
const NotificationsContext = React.createContext();
// const defaultNotifications = () => [1, 2];

export function NotificationsProvider(props) {
  const [notifications, setNotifications] = React.useState([]);
 const [notifiedFolios, setnotifiedFolios] = React.useState([]);
 const [messages, setMessages] = React.useState([]);



  const [play] = useSound(boopSfx);

  React.useEffect(() => {

    const q = query(collection(firestore, "mensajes-servicio"),where("visto","==",0));
    const mensajes = [];
    const folios = [];
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      querySnapshot.forEach((doc2) => {
        const data_1=doc2.data();
        const state=notifiedFolios.includes(data_1.id);
        const state2=mensajes.includes(data_1.id);
        if( state===false && state2===false && data_1.idUsuario !== "hermogas"  && data_1?.notificado !== 1   ){
          updateDoc(doc(collection(firestore,"mensajes-servicio"),doc2.id),{"notificado": 1}).then(()=>{

            let obj={};
            obj=doc2.data();
            obj.id=doc2.id;
            mensajes.push(obj);
            folios.push(doc2.id);
            console.log(mensajes);
            notification.open({
              message: 'Nuevo mensaje de '+data_1.nombreUsuario,
              description:
               "Mensaje: "+data_1.mensaje,
         onClick: () => {
              },
            }); 
            play();
          });
        }
      });
    });
      setnotifiedFolios(folios);
      setMessages(mensajes);
  }, []);
 

  
  // COMPONENTE SE MONTÓ
  React.useEffect(() => {
    console.log(messages);
/*     if(messages.length>0){
      for (const msg in messages) {
        notification.open({
          message: 'Nuevo mensaje de '+messages[msg].nombreUsuario,
          description:
           "Mensaje: "+messages[msg].mensaje,
     onClick: () => {
          },
        }); 
        play();
        setMessages([])
  
      }
    } */

  }, [messages]);
  const memData = React.useMemo(() => {
    // AQUI SE PONE TODO LO Q EL HOOK EXPORTA
    return { notifications, setNotifications };
  }, [notifications]);

  return <NotificationsContext.Provider value={memData} {...props} />;
}

export function useNotifications() {
  const context = React.useContext(NotificationsContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: notifications context not defined.";
  }
  return context;
}
