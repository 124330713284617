import React from "react";
import { Breadcrumb, Typography, Space, Row, Col, Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Content } from "antd/lib/layout/layout";

const { Title } = Typography;

const defaultBreadcrumbItem = {
  name: "Inicio",
  to: "/",
  icon: <HomeOutlined />,
};

const SimpleViewLayout = ({
  title,
  mainActionButton,
  children: ChildComponents,
  breadcrumb = [],
}) => {
  const history = useHistory();
  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        {[defaultBreadcrumbItem, ...breadcrumb].map((bc, index) => (
          <Breadcrumb.Item key={index} href={bc.to}>
            {bc.icon}
            <span>{bc.name}</span>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <Row justify="space-between">
        <Col>
          <Title level={4}>{title || "Página sin titulo"}</Title>
        </Col>
        <Col>
          {Boolean(mainActionButton) && (
            <Button
              onClick={
                mainActionButton.to
                  ? () => history.push(mainActionButton.to)
                  : undefined
              }
              {...mainActionButton.props}
            >
              {mainActionButton.text} {mainActionButton.icon}
            </Button>
          )}
        </Col>
      </Row>
      <Space
        direction="vertical"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {ChildComponents}
        </Content>
      </Space>
    </>
  );
};

export default SimpleViewLayout;
