import React from 'react'
import DefaultLayout from './DefaultLayout';
import { Input, ConfigProvider, Empty, Col, Row } from "antd";

const { Search } = Input;

const SimpleTableLayout = ({ 
        breadcrumbItems, 
        buttonData, 
        title,
        searchPlaceholder="Búsqueda",
        searchLoading,
        searchProps = {
            size: "large"
        },
        onSearchClicked,
        showSearchButton = true,
        children,
        emptyText="Aún no hay registros.",
}) => {

    return (
        <DefaultLayout 
            breadcrumbItems={breadcrumbItems} 
            buttonData={buttonData}
            title={title} >

                {
                    showSearchButton && (
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 9, offset: 15 }} lg={{ span: 7, offset: 17 }}  xxl={{ span: 5, offset: 19 }}>
                                <Search
                                placeholder={searchPlaceholder}
                                enterButton="Buscar"
                                style={{ width: '100%',  marginBottom: 10 }}
                                loading={searchLoading}
                                onSearch={onSearchClicked}
                                {...searchProps}
                                />
                            </Col> 
                        </Row>
                    )
                }

        
                <ConfigProvider
                    renderEmpty={() => (
                        <Empty
                            style={{ height: 300, paddingTop: "5%" }}
                            description={emptyText}
                        />
                    )} >

                    { children }

                </ConfigProvider>

        </DefaultLayout>
    )
}

export default SimpleTableLayout;
