import React from "react";
import { Input, ConfigProvider, Empty } from "antd";
import SimpleViewLayout from "./SimpleViewLayout";

const { Search } = Input;

const SimpleTableViewLayout = ({
  mainButton,
  searchLoading,
  onSearchClicked,
  children: TableComponent,
  title = "",
  searchPlaceholder = "Búsqueda",
  emptyText = "No hay registros en esta tabla...",
}) => {
  return (
    <SimpleViewLayout title={title} mainActionButton={mainButton}>
      <Search
        placeholder={searchPlaceholder}
        enterButton="Buscar"
        size="large"
        loading={searchLoading}
        onSearch={onSearchClicked}
      />

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            style={{ height: 300, paddingTop: "5%" }}
            description={emptyText}
          />
        )}
      >
        {TableComponent}
      </ConfigProvider>
    </SimpleViewLayout>
  );
};

export default SimpleTableViewLayout;
