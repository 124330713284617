import { React, useEffect, useState } from "react";
import { useQuery } from "../../hooks"
import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin,DatePicker,InputNumber,Switch,Upload, message } from 'antd'
import {
  EditOutlined,
  PlusOutlined,
  CodepenOutlined 
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import 'moment/locale/es-mx';
import locale from 'antd/es/date-picker/locale/es_ES';
import { auth,firestore,storage } from "../../services/firebase";
import { getDoc ,setDoc, doc, serverTimestamp,updateDoc, addDoc, collection } from "firebase/firestore";
import DefaultLayout from "../../components/layouts/DefaultLayout";
import { initializeApp,deleteApp} from "firebase/app";
import { getAuth,createUserWithEmailAndPassword,signOut } from 'firebase/auth';
import { UploadOutlined } from '@ant-design/icons';
import {uploadString,getDownloadURL,ref} from "firebase/storage";

const CompanyForm = () => {
  const q = useQuery()
  const idPaquete = q.get("id")?.replace(' ','')
  const editing = !!idPaquete
  const history = useHistory()
  const [saveLoading, setSaveLoading] = useState(false)
  const [form] = Form.useForm()
  const {Option} = Select
  const currentUser = auth.currentUser.uid;
  const [model, setModel] = useState({})
  const [pagos, setPagos] = useState("")
  const [modelLoading, setModelLoading] = useState(true)
  const [fileList, setFileList] = useState([]);
  const [imageChanged, setimageChanged] = useState(false);
  useEffect(() => {
    if(!editing){setModelLoading(false);}
        if(editing && modelLoading){ 
          const ref= doc(firestore,"paquetes",idPaquete.replace(" ",""))
           getDoc(ref).then(docSnap=>{
            if (docSnap.exists()) {
              const data=docSnap.data();
              setFileList([{url:data.pathFirebase,name:data.fileName,status:"done"}])
              setModel(data);
              if (modelLoading && model) {
                console.log(model);
                try{
                model.date=moment(model.date.toDate());
                }
                catch(error){
                  console.log(error);
                }     
             var model_copy=model;
              form.setFieldsValue(
                model_copy
                );
              setModelLoading(false);
          
              }
    
            } else {
              console.log("No such document!");
            }
          })
        } 
    
    
      }, [model, form]);

  const onChangeFile = ({ fileList: newFileList }) => {
    setimageChanged(true);
    if(newFileList.length>1){
      setFileList([newFileList[1]]);
    }else{
      setFileList(newFileList);
    }
  };
  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  
  const breadcrumb=[
    { name: "Paquetes", to: "/admin/paquetes", icon: <CodepenOutlined /> },
    { name: editing?"Editar":"Nuevo", to: editing ? `/admin/paquetes/editar?id=${idPaquete}` : "/admin/paquetes/nueva", icon: editing?<EditOutlined />:<PlusOutlined /> }
  ];
  useEffect(() => {
    if(!editing)  
    setModelLoading(false);
    if(editing && modelLoading){
      console.log(idPaquete);
      const ref= doc(firestore,"paquetes",idPaquete)
       getDoc(ref).then(docSnap=>{
        if (docSnap.exists()) {
          const data=docSnap.data();
          setModel(data);
          form.setFieldsValue(model);
          setModelLoading(false);
        } else {
          console.log("No such document!");
        }
      })
    } 
  }, [model, form, editing, modelLoading, idPaquete]); 
  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },};

  const messageSuccess = () => {
    let secondsToGo = 2;
    const modal = Modal.success({
      title: "Datos actualizados correctamente!",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { style: { display: 'none' } }
    });

    const timer = setInterval(() => {secondsToGo -= 1}, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }

  const onSubmit2 = async (e) => {
    try{
      try{
        await form.validateFields()
      }catch{
        return
      }
    setSaveLoading(true)
      let data = form.getFieldsValue(true);
      delete data["foto"];
      data.user=currentUser;
      console.log(data);
    if (editing){ 
      if (imageChanged){

      setModelLoading(false);
      var  thumbUrl=fileList[0]?.thumbUrl;
      if(fileList.length>0){
        history.push("/admin/paquetes");
        thumbUrl=thumbUrl.replace("data:image/jpeg;base64,","")
        thumbUrl=thumbUrl.replace("data:image/png;base64,","")
        thumbUrl=thumbUrl.replace("data:image/JPEG;base64,","")
        thumbUrl=thumbUrl.replace("data:image/jpg;base64,","")
        thumbUrl=thumbUrl.replace("data:image/JPG;base64,","")
        thumbUrl=thumbUrl.replace("data:image/PNG;base64,","")
        uploadString(ref(storage,"/images/"+fileList[0]?.name),thumbUrl,"base64").then(file=>{
          getDownloadURL(file.ref).then(url=>{
            console.log(url);
            data.fileName=file.ref.name;
            data.pathFirebase=url;
            updateDoc(doc(firestore,"paquetes",idPaquete),data);
            messageSuccess();
            })
        })
      }
    }else{
      history.push("/admin/paquetes");
      setModelLoading(false);
      updateDoc(doc(firestore,"paquetes",idPaquete),data);
      messageSuccess();
      }
    }else{
      setModelLoading(false);
      var  thumbUrl=fileList[0]?.thumbUrl;
      if(fileList.length>0){
        history.push("/admin/paquetes");
        thumbUrl=thumbUrl.replace("data:image/jpeg;base64,","")
        thumbUrl=thumbUrl.replace("data:image/png;base64,","")
        thumbUrl=thumbUrl.replace("data:image/JPEG;base64,","")
        thumbUrl=thumbUrl.replace("data:image/jpg;base64,","")
        thumbUrl=thumbUrl.replace("data:image/JPG;base64,","")
        thumbUrl=thumbUrl.replace("data:image/PNG;base64,","")
        uploadString(ref(storage,"/images/"+fileList[0]?.name),thumbUrl,"base64").then(file=>{
        console.log(file);
        getDownloadURL(file.ref).then(url=>{
          console.log(url)
          addDoc(collection(firestore, "paquetes"),{}).then(id=>{
            data.id=id.id;
            data.fileName=file.ref.name;
            data.pathFirebase=url;
            setDoc(doc(firestore,"paquetes",id.id),data);
          });
        })
        })
      }
      else{
        history.push("/admin/paquetes");
        addDoc(collection(firestore, "paquetes"),{}).then(id=>{
        data.id=id.id;
        setDoc(doc(firestore,"paquetes",id.id),data);
        });
      }
    }
    }catch(error){
    console.log(error)
    } finally{
    setSaveLoading(false)
    }
    };

if(modelLoading) return <Space size="middle"><Spin size="large"/></Space>
  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      title={editing ? `Editando paquete: ${model.nombre}` : "Nuevo paquete"}
    >
      <Form
        component="div"
        form={form}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>

        <Col sm={12} md={4}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="nombre"
              label="Nombre"
              hasFeedback
            >
              <Input
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={2}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="precio"
              label="Precio"
              hasFeedback

            >
              <InputNumber
              min={1}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={2}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="years"
              label="Años"
              hasFeedback
            >
              <InputNumber
              min={1}
              />
            </Form.Item>
          </Col> 
          <Col sm={12} md={2}>
            <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="cobertura"
              label="Cobertura"
              hasFeedback
            >
              <InputNumber
              min={1}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={3}>
          <Form.Item 
            style={{ marginBottom: '0px' }}
            required
            name="activo"
            label="Activo"
            hasFeedback

          >
            <Switch
          
            defaultChecked={model.activo}
            style={{width:"70%"}}
            />
          </Form.Item>
        </Col>
          <Col sm={12} md={8}>
          <Form.Item 
              style={{ marginBottom: '0px' }}
              required
              name="foto"
              label="Foto Promocional"
              hasFeedback
            >
      <Upload
        action={console.log("Uploaded")}
        listType="picture-card"
        fileList={fileList}
        onChange={onChangeFile}
        onPreview={onPreview}
      >
        {fileList.length < 5 && '+ Upload'}
      </Upload>
      </Form.Item>
          </Col>

</Row>
        <Row gutter={[16, 0]}  style={{ marginTop: '10px' }}>
          <Col sm={24}>
            <Form.Item>
              <Button 
                type="primary" 
                onClick={onSubmit2}
                loading={saveLoading}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row> 
      </Form>
    </DefaultLayout>
  );
};

export default CompanyForm;
