import React,{useEffect,useState} from "react";
import { Layout, Menu, Row, Col, Avatar } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../../hooks";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { dashboardRoutes,adminRoutes } from "../../routers";
import "../../styles/DashboardLayout.less";
import { getDoc,doc,collection,where } from "firebase/firestore";
import firebase, { auth, firestore } from "../../services/firebase";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const rootSubmenuKeys = [""];

const DashboardLayout = ({ children: PanelContent }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState([""]);
  const [selectedKey, setSelectedKey] = React.useState("");
  const location = useLocation();
  const { user, userLoading, signOut } = useAuth();
  const history = useHistory();
  const [currentUser, setcurrentUser] = useState({});
  const [routes, setRoutes] = useState(dashboardRoutes);
  const currenUid=auth.currentUser.uid;
  useEffect(() => {
  getDoc(doc(firestore,"users",currenUid)).then(doc=>{
    let data=doc.data();
    setcurrentUser(data);
    if(data.role=="admin"){
    setRoutes(adminRoutes);
    }
  })
  }, []);
  React.useEffect(() => {
    let mounted = true;
    if (mounted) setCollapsed(false);
    return () => mounted = false;
  }, [])
  React.useEffect(() => {
    const flatter = (r) =>
      r?.routes
        ? [
            r,
            ...r?.routes
              .map((sub) => ({ ...sub, path: r.path + sub.path }))
              .flatMap(flatter),
          ]
        : r;

      
    const flatted = routes.flatMap(flatter);
    const paths = flatted.map((r) => r.path);
    const key = paths.find((path) => path === location.pathname);
    // TODO: revisar active cuando navegas hacia adentro.
    setSelectedKey(key);
    const tmpOpenKeys = flatted
      .filter(
        (r) => r.sidebar === "collapse" && location.pathname.includes(r.path)
      )
      .map((r) => "collapse-" + r.path);
    setOpenKeys(tmpOpenKeys);
  }, [location]);

  const onCollapse = () => {
    setCollapsed( c => !c );
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const sidebarMapper = (route) => {
    if (route.sidebar === "single") {
      return (
        <Menu.Item
          key={route.path}
          onClick={() => setSelectedKey(route.path)}
          icon={route.icon}
        >
          <NavLink to={route.path}>{route.name}</NavLink>
        </Menu.Item>
      );
    } else if (route.sidebar === "collapse") {
      const innerMap = (r) => ({ ...r, path: route.path + r.path });
      const finalKey = "collapse-" + route.path;
      return (
        <SubMenu key={finalKey} icon={route.icon} title={route.name}>
          {route?.routes?.map(innerMap).map(sidebarMapper)}
        </SubMenu>
      );
    }
    return null;
  };

  if (!user && userLoading) return null;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        id="auth-sider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme="light"
        width={236}
      >
        <img src={ collapsed ? "/img/logosm.png" : "/img/logo192.png" } alt="logotipo" style={{padding: 10 }} width={ collapsed ? 200 : 200 } />


        <Menu
          mode="inline"
          openKeys={ collapsed ? [] : openKeys  }
          onOpenChange={onOpenChange}
          className="sider-menu"
          selectedKeys={selectedKey}
          inlineIndent={18}
        >

          {routes.map(sidebarMapper)}

          <Menu.Item
            key="single-/0"
            icon={<LogoutOutlined />}
            onClick={signOut}
          >
            Cerrar sesión
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0, zIndex: 9 }}>
          <Row justify="space-between" style={{ padding: "0px 10px" }}>
            <Col>
              <span style={{ fontSize: 10, color: "gray" }}> </span>
            </Col>
            <Col>
              <Row>
                <Col>{user?.email}</Col>
                <Col style={{ marginLeft: 10 }}>
                  <Avatar 
                    style={{cursor: "pointer"}}
                    onClick={() => history.push("/perfil")}
                    size={42} 
                    icon={<UserOutlined />}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content style={{ margin: "0 16px" }}>{PanelContent}</Content>
        <Footer>Promenal { new Date().getFullYear() } &#xa9;</Footer>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;