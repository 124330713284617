import { React,useState,useEffect } from "react";
import {

  BookOutlined
} from "@ant-design/icons";
import SimpleLayout from "../../components/layouts/DefaultLayout";
import Clasico from "../../components/productDetails/clasico";
import Vip from "../../components/productDetails/vip";
import Premier from "../../components/productDetails/premier";
import { Row ,Card, Button,Divider} from 'antd';
import {auth,firestore} from "../../services/firebase";
import ViewLoading from '../../components/ViewLoading'
import { getDoc , doc,serverTimestamp } from "firebase/firestore";
import moment from "moment-timezone";
const { Meta } = Card;

const viewTitle = "Póliza";
const Poliza = () => {
  const currentUser=auth.currentUser.uid;
  const [product, setProduct] = useState();
  const [activeProduct, setactiveProduct] = useState();
  const [model, setModel] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
      const ref= doc( firestore, "users", currentUser )
      getDoc(ref).then(docSnap=>{
        if (docSnap.exists()) {
          const data=docSnap.data();
          console.log(data);
          setModel(data);
          setProduct(data.paquete);
          setLoading(false);
          let ft=data.fechaTerminacion.seconds;
          let stamp=(moment.now())/1000;
          console.log(stamp)
          console.log(ft)

          if(ft>stamp){
          setactiveProduct(data.paquete);
          }
        } else {
          console.log("No such document!");
        }
      })
  }, []);
const breadcrumb=[
  { name: "Póliza", to: "poliza", icon: <BookOutlined /> }
];
  return (
    <SimpleLayout
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      children={
        <div>
          {loading?(<ViewLoading/>):null}
          {product=="CLASICO"?(<Clasico date={model.fechaTerminacion} active={activeProduct != "CLASICO" ? false :  true} />):null}
          {product=="VIP"?(<Vip date={model.fechaTerminacion} active={activeProduct != "VIP" ? false :  true} />):null}
          {product=="PREMIER"?(<Premier date={model.fechaTerminacion} active={activeProduct != "PREMIER" ? false :  true} />):null}
<Row  style={{alignItems: 'center',justifyContent:"center" ,backgroundSize: "cover",backgroundColor:"white"}}>
  <div style={{ paddingRight:25,paddingLeft:25,paddingTop:50,paddingBottom:50}}>
  <h1 style={{fontWeight:"bold",fontSize: '35px', color: 'black',paddingBottom:20 }}>Paquetes</h1>
  <Row  type="flex" style={{alignItems: 'center',justifyContent:"center" }}>
    <div style={{paddingLeft:25,paddingRight:25,paddingTop:30}}>
      <Card
        onClick={()=>{
          setProduct("CLASICO");
        }}
        hoverable
        style={{ width: 300}}
        cover={     <img src={"img/clasico.png"}></img>
      }
      >
      <Meta title="CLÁSICO" description="$12,500 MXN"/>
      </Card>
    </div>
    <div style={{paddingLeft:25,paddingRight:25,paddingTop:30}}>
      <Card
        onClick={()=>{
          setProduct("PREMIER");

          }}
        hoverable
        style={{ width: 300}}
        cover={     <img src={"img/PREMIER.png"}></img>
      }
      >
      <Meta title="PREMIER" description="$14,000 MXN"/>
      </Card>
    </div>
    <div style={{paddingLeft:25,paddingRight:25,paddingTop:30}}>
      <Card
        onClick={()=>{
          setProduct("VIP");
        }}
        hoverable
        style={{ width: 300}}
        cover={<img src={"img/vip.png"}></img>}>
        <Meta title="VIP" description="$16,000 MXN"/>
      </Card>
    </div>
  </Row>
</div>
</Row>
</div>
      }
    >
    </SimpleLayout>
  );
};
export default Poliza;
