import React from "react";
import { Form, Input, Button, Row, Col, Card} from "antd";
import { useAuth } from "../../hooks";
import {Modal} from "antd";
import { useHistory } from "react-router-dom";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const messageError = (title) => {
  let secondsToGo = 2;
  const modal = Modal.error({
    title:title,
    cancelButtonProps: { style: { display: 'none' } },
    okButtonProps: { style: { display: 'none' } }
  });
  const timer = setInterval(() => {secondsToGo -= 1}, 1000);
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
  }, secondsToGo * 1000);
}
const SignUp = () => {
  const { signUp, sessionLoading } = useAuth();
  const history = useHistory()

  const onFinish = (values) => {
    const { email, password } = values;
    console.log(values);
    signUp(email, password);
    history.push("/");
  };
  const onFinishFailed = (errorInfo) => {
    messageError("Contraseña debe tener al menos 8 caracteres");
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Row  style={{paddingTop: "10%"}}> 
        <Col span={6} md={12} lg={10} xs={22} offset={2} style={{ textAlign: "center", marginBottom: 25 }}>
          {<img src="img/logo192.png" alt="logo" width={450} style={{marginTop: 0}} /> }
        </Col>
        <Col span={6} md={6} lg={6} xs={22}  offset={1}>
          <Card
            style={{
              minWidth: 450,
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)" 
            }}
            title="REGISTRARSE"
            headStyle={{
              color: "#3E9BBC"
            }}
          >
            <Form {...layout} 
              name="basic"
              initialValues={{
                email: "",
                password: "",
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Correo electrónico"
                name="email"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Favor de ingresar su correo electrónico.",
                    type: "email"
                  },
                ]}
              >
                <Input type="email"/>
              </Form.Item>
              <Form.Item
              
                label="Contraseña"
                name="password"
                hasFeedback
                rules={[
                  {
                    min:8,
                    required: true,
                    message: "Favor de ingresar su contraseña.",
                  },
                ]}
              >
                <Input.Password/>
              </Form.Item>
              <Form.Item
                label="Contraseña"
                name="passwordConfirmation"
                hasFeedback
                
                rules={[
                  {
                    min:8,
                    required: true,
                    message: "Favor de ingresar su contraseña.",
                  },
                ]}
              >
                <Input.Password/>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={sessionLoading}> 
                  Registrarse
                </Button>
              </Form.Item>
            </Form>
          <a href={"/"} style={{fontSize:20}}>Ya tienes cuenta? Inicia sesión!</a>
          </Card>
        </Col>
      </Row>
      <Row>

      </Row>
    </div>
  );
};

export default SignUp;
