import { React, useState, useEffect } from "react";
import {  Descriptions, Table, Modal, Divider, Switch } from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined
} from "@ant-design/icons";
import { red } from "@ant-design/colors";
import { DropdownOverlayButton } from "../../components";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import { useHistory } from "react-router-dom"
import { onSnapshot,doc,query,collection,where} from "@firebase/firestore";
import { firestore } from "../../services/firebase";
import {  deleteDoc,getDoc} from "firebase/firestore";
import { auth } from "../../services/firebase";
const viewTitle = "Administración de paquetes";
const searchPlaceholder = "Búsqueda...";
const emptyText = "No hay paquetes registrados...";
const Paquetes = () => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [displayModels, setDisplayModels] = useState([]);
  const [modelsLoading, setModelsLoading] = useState(true);
  const history = useHistory();
  const mainButton = { text: "Nuevo", icon: <UserOutlined />, to: ()=> history.push("paquetes/nueva") };
  const breadcrumb = [ { name: "Paquetes", to: "paquetes", icon: <UserOutlined /> } ];

  useEffect(() => {
        const q = query(collection(firestore, "paquetes"));
        onSnapshot(q, (querySnapshot) => {
          const paquetes = [];
          querySnapshot.forEach((doc) => {
              paquetes.push(doc.data());
          });
          const sortedObjs = paquetes.sort((a, b) => a.nombre.localeCompare(b.nombre));
          setModels(sortedObjs);
          setModelsLoading(false);
        });
    }, []);

  const renderSimple = (prop) => prop || <i>--</i>;
  const columns = [
    {
      title: "Nombre",
      width: 150,
      dataIndex: "nombre",
      key: "nombre",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Precio",
      width: 150,
      dataIndex: "precio",
      key: "precio",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Años",
      width: 150,
      dataIndex: "years",
      key: "years",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Cobertura",
      width: 150,
      dataIndex: "cobertura",
      key: "cobertura",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "center",
      width: 100,
      render: (text, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Editar información",
              icon: <LockOutlined />,
              onClick: () => history.push(`paquetes/editar?id=${row.id}`)
            },

            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => modalDelete(row),
              styleProps: {
                color: red[4],
              },
            },
          ]}
        />
      ),
    },
  ];
  const modalDelete = (v) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar el paquete "${v.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        try {
          const ref= doc(firestore,"paquetes",v.id)
          await deleteDoc(ref);
        } catch (error) {
          console.log(error);
        }
      },
    });
  };
  const onSearchClicked = async (v) => {
    setSearchLoading(true);
    setTimeout(() => setSearchLoading(false), 1500);
  };
  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      buttonData={mainButton}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearchClicked}
      emptyText={emptyText}
      children={
        <Table
          columns={columns}
          dataSource={models}
          rowKey="id"
          loading={modelsLoading}
        />
      }
    >
    </SimpleTableLayout>
  );
};

export default Paquetes;
