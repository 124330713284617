import React from 'react';
import { Breadcrumb as AntdBreadcrumb, Divider } from 'antd';
import { HomeOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

const BreadCrumb = ( { breadcrumbItems = []  } ) => {

    
    const defaultBreadcrumbItem = [
        { name: "Inicio", to: "/", icon: <HomeOutlined /> }, 
        ...breadcrumbItems,
    ];
    
    return (
        <>
            <AntdBreadcrumb style={{ marginTop: 20 }}>
            { defaultBreadcrumbItem.map((item, index ) => (
                <AntdBreadcrumb.Item key={index} >
                    <Link to={item?.to}>
                    {item?.icon}
                        <span> {item?.name} </span>
                    </Link>
                </AntdBreadcrumb.Item>
             ))}
            </AntdBreadcrumb>
            
            <Divider />
        </>
    )
}

export default BreadCrumb;