import React from "react";
import { getIdToken, onIdTokenChanged } from "firebase/auth";
import { auth } from "../services/firebase";

const AppContext = React.createContext();

export function AppProvider(props) {
  const [token, setToken] = React.useState(null);

  React.useEffect(() => {
    let unsub = onIdTokenChanged(auth, (user) => {
      if(user) {
        getIdToken(user).then(token => {
          if(token) {
            setToken(token);
          }
        });  
      }
    });

    return () => {
      unsub();
    };
  }, []);

  const memData = React.useMemo(() => {
    return { token, setToken };
  }, [token, setToken]);

  return <AppContext.Provider value={memData} {...props} />;
}

export function useApp() {
  const context = React.useContext(AppContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: app context not defined.";
  }
  return context;
}
