import { React, useState, useEffect } from "react";
import {  Descriptions, Table, Modal, Divider, Switch } from "antd";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  LockOutlined,
  UserOutlined
} from "@ant-design/icons";
import { red } from "@ant-design/colors";
import { DropdownOverlayButton } from "../../components";
import SimpleTableLayout from "../../components/layouts/SimpleTableLayout";
import { useHistory } from "react-router-dom"
import { onSnapshot,doc,query,collection,where} from "@firebase/firestore";
import { firestore } from "../../services/firebase";
import {  deleteDoc,getDoc} from "firebase/firestore";
import { auth } from "../../services/firebase";

const viewTitle = "Administración de Usuarios";
const searchPlaceholder = "Búsqueda...";
const emptyText = "No hay usuarios registrados...";

const Usuarios = () => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [models, setModels] = useState([]);
  const [modelsLoading, setModelsLoading] = useState(true);
  const history = useHistory();
  const mainButton = { text: "Nuevo", icon: <UserOutlined />, to: ()=> history.push("usuarios/nueva") };
  const breadcrumb = [ { name: "Usuarios", to: "usuarios", icon: <UserOutlined /> } ];

  useEffect(() => {
    let mounted = true;
    const ref= doc(firestore,"users",auth.currentUser.uid)
    getDoc(ref).then(docSnap=>{
     if (docSnap.exists()) {
      const data=docSnap.data();


      if(data.role=="admin" ){
        const q = query(collection(firestore, "users"),where("role","==","client"));
        onSnapshot(q, (querySnapshot) => {
          const usuarios = [];
          querySnapshot.forEach((doc) => {
              usuarios.push(doc.data());
          });
          const sortedObjs = usuarios.sort((a, b) =>  - b.fecha-a.fecha)
          setModels(sortedObjs);
          setModelsLoading(false);
        });
      }else{
        const q = query(collection(firestore, "users"),where("user","==",auth.currentUser.uid));
        onSnapshot(q, (querySnapshot) => {
          const usuarios = [];
          querySnapshot.forEach((doc) => {
              usuarios.push(doc.data());
          });
          const sortedObjs = usuarios.sort((a, b) =>  - b.fecha-a.fecha)
          setModels(sortedObjs);
          setModelsLoading(false);
        });
      }


     }});

  }, []);

  const renderSimple = (prop) => prop || <i>--</i>;

  const columns = [
    {
      title: "Nombre",
      width: 150,
      dataIndex: "nombre",
      key: "nombre",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Correo",
      width: 150,
      dataIndex: "email",
      key: "email",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Teléfono",
      width: 150,
      dataIndex: "telefono",
      key: "telefono",
      fixed: "left",
      render: renderSimple
    },
    {
      title: "Paquete",
      width: 150,
      dataIndex: "paquete",
      key: "paquete",
      fixed: "left",
      render: (paquete)=>paquete?.nombre
    },
    {
      title: "Fecha de Vencimiento",
      width: 150,
      dataIndex: "fechaTerminacion",
      key: "fechaTerminacion",
      fixed: "left",
      render:  (fechaCierre)=>fechaCierre ? fechaCierre?.toDate().toLocaleDateString('es-mx')+" "+fechaCierre?.toDate().toLocaleTimeString('es-mx') : "Sin asignar"
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "center",
      width: 100,
      render: (text, row) => (
        <DropdownOverlayButton
          options={[
            {
              name: "Ver detalle",
              icon: <EyeOutlined />,
              onClick: () => modalInfo(row)
            },
            {
              name: "Editar información",
              icon: <LockOutlined />,
              onClick: () => history.push(`usuarios/editar?id=${row.id}`)
            },

            {
              name: "Eliminar",
              icon: <DeleteOutlined />,
              onClick: () => modalDelete(row),
              styleProps: {
                color: red[4],
              },
            },
          ]}
        />
      ),
    },
  ];

  const ContentModal = ({row}) => {
    return (
      <div>
                <Descriptions
          title="Información de Pago"
          size="small"
        >
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Paquete" >{row?.paquete?.nombre}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Cobertura" >{row?.paquete?.cobertura}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Vigencia" >{row?.paquete?.years+" Años"}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Fecha de Inicio">{row?.paquete?.fecha?.toDate().toLocaleDateString('es-mx')+" "+row?.paquete?.fecha?.toDate().toLocaleTimeString('es-mx')}</Descriptions.Item>

          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Metodo de Pago" >{row?.metodoPago}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Deposito" >{row?.deposito}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Fecha de Vencimiento">{row?.fecha?.toDate().toLocaleDateString('es-mx')+" "+row?.fecha?.toDate().toLocaleTimeString('es-mx')}</Descriptions.Item>

        
        </Descriptions>
        <Descriptions
          title=""
          size="small"
        >
         
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Fecha de Primer Pago">{row?.primerpagoFecha?.toDate().toLocaleDateString('es-mx')+" "+row?.primerpagoFecha?.toDate().toLocaleTimeString('es-mx')}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Primer Pago" >{row?.primerpago}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Pagado" >  <Switch defaultChecked={row?.primerPagado}/></Descriptions.Item>


        </Descriptions>
        <Descriptions
          title=""
          size="small"
        >

          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Fecha de Segundo Pago">{row?.segundopagoFecha?.toDate().toLocaleDateString('es-mx')+" "+row?.segundopagoFecha?.toDate().toLocaleTimeString('es-mx')}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Segundo Pago" >{row?.segundopago}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Pagado" ><Switch defaultChecked={row?.segundoPagado}/></Descriptions.Item>
         

        </Descriptions>
        <Descriptions
          title=""
          size="small"
        >
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Fecha de Tercer Pago">{row?.tercerpagoFecha?.toDate().toLocaleDateString('es-mx')+" "+row?.tercerpagoFecha?.toDate().toLocaleTimeString('es-mx')}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Tercer Pago" >{row?.tercerpago}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Pagado" ><Switch defaultChecked={row?.tercerPagado}/></Descriptions.Item>
        
        
        </Descriptions>
        
        <Divider/>
        <Descriptions
          title=""
          size="small">
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Nombre" >{row?.nombre}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Apellido Paterno" >{row?.apellidoPaterno}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Apellido Materno" >{row?.apellidoMaterno}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="R.F.C." >{row?.rfc}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="CURP" >{row?.curp}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Correo">{row?.email}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Teléfono">{row?.telefono}</Descriptions.Item>
          <Descriptions.Item  labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Cédula Professional">{row?.cedulaProfessional}</Descriptions.Item>
          <Descriptions.Item  labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Especialidad">{row?.especialidad}</Descriptions.Item>
          <Descriptions.Item  labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Cédula de Especialidad">{row?.cedulaEspecialidad}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Fecha de Creacion">{row?.fecha?.toDate().toLocaleDateString('es-mx')+" "+row?.fecha?.toDate().toLocaleTimeString('es-mx')}</Descriptions.Item>
        </Descriptions>
<Divider/>

        <Descriptions
        style={{paddingTop:20}}
          title="Domicilio"
          size="small"
        >
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Calle" >{row?.calle}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Numero Exterior" >{row?.numeroExterior}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Numero Interior" >{row?.numeroInterior}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Colonia" >{row?.colonia}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Municipio" >{row?.municipio}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Estado">{row?.estado}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Código Postal">{row?.codigoPostal}</Descriptions.Item>
        </Descriptions>
<Divider/>
        <Descriptions
        style={{paddingTop:20}}
          title="Consultorio"
          size="small"
        >
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Calle" >{row?.calleHospital}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Numero Exterior" >{row?.numeroExteriorConsultorio}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Numero Interior" >{row?.numeroInteriorConsultorio}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Colonia" >{row?.coloniaConsultorio}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Municipio" >{row?.municipioConsultorio}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Estado">{row?.estadoConsultorio}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Código Postal">{row?.codigoPostalConsultorio}</Descriptions.Item>
        </Descriptions>
        <Divider/>

        <Descriptions
        style={{paddingTop:20}}
          title="Hospital"
          size="small"
        >
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Calle" >{row?.calleHospital}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Numero Exterior" >{row?.numeroExteriorHospital}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Numero Interior" >{row?.numeroInteriorHospital}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Colonia" >{row?.coloniaHospital}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Municipio" >{row?.municipioHospital}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Estado">{row?.estadoHospital}</Descriptions.Item>
          <Descriptions.Item labelStyle={{fontWeight: '700',"font-weight": "bold"}} label="Código Postal">{row?.codigoPostalHospital}</Descriptions.Item>
        </Descriptions>

      </div>
    )
  }

  const modalInfo = (v) => {
    Modal.confirm({
      title: "",
      icon: null,
      content: <ContentModal row={v}/>,
      okText: "Ok",
      cancelButtonProps: { style: { display: 'none' } },
      okButtonProps: { primary: true },
      closable: true,
      width:1000
    });
  };

  const modalDelete = (v) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar al usuario "${v.id}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        try {
          const ref= doc(firestore,"users",v.id)
          await deleteDoc(ref);
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const onSearchClicked = async (v) => {
    setSearchLoading(true);
    setTimeout(() => setSearchLoading(false), 1500);
  };

  return (
    <SimpleTableLayout
      breadcrumbItems={breadcrumb}
      title={viewTitle}
      buttonData={mainButton}
      searchPlaceholder={searchPlaceholder}
      searchLoading={searchLoading}
      onSearchClicked={onSearchClicked}
      emptyText={emptyText}
      children={
        <Table
          columns={columns}
          dataSource={models}
          rowKey="id"
          loading={modelsLoading}
        />
      }
    >
    </SimpleTableLayout>
  );
};

export default Usuarios;
