import React, { useEffect ,useState} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppLoading } from "../components";
import { DashboardLayout } from "../components/layouts";
import { useAuth } from "../hooks";
import { dashboardRoutes,adminRoutes } from "./routes";
import firebase, { auth, firestore } from "../services/firebase";
import { getDoc,doc,collection,where } from "firebase/firestore";
const routeMapper = (route, index) =>
  route?.routes?.length > 0 ? (
    route?.routes
      ?.map((r) => ({ ...r, path: route.path + r.path, layout: route.layout }))
      .map(routeMapper)
  ) : (
    <Route
      key={route.path + (index + 1).toString()}
      exact={Boolean(route.layout === "dashboard")}
      path={route.path}
      render={(props) => <route.component {...props} route={route} />}
    />
  );

const PrivateRouter = () => {
  const { user, userLoading } = useAuth();
  const [currentUser, setcurrentUser] = useState({});

  const currenUid=auth.currentUser.uid;
  useEffect(() => {
  getDoc(doc(firestore,"users",currenUid)).then(doc=>{
    setcurrentUser(doc.data());
  })
  }, []);

  if (!user && userLoading && currentUser) {
    return <AppLoading />;
  }

  return (
    <Router>
      <DashboardLayout>
        {currentUser.role=="admin"?(
        <Switch>{adminRoutes.map(routeMapper)}</Switch>

        ):(

        <Switch>{dashboardRoutes.map(routeMapper)}</Switch>
        )}
      </DashboardLayout>
    </Router>
  );
};

export default PrivateRouter;
